var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "test-forms" }, [
    _c("h1", [_vm._v("Row Layout")]),
    _c(
      "div",
      { staticClass: "flex-row" },
      [
        _c("label", { attrs: { for: "b-select-one" } }, [
          _vm._v("BV Select Box")
        ]),
        _c(
          "b-select",
          { attrs: { id: "b-select-one" } },
          [
            _c("b-select-option", { attrs: { value: "1", disabled: "" } }, [
              _vm._v("Disabled Opt")
            ]),
            _c("b-select-option", { attrs: { value: "2" } }, [
              _vm._v("Option Two")
            ]),
            _c(
              "b-select-option-group",
              { attrs: { label: "Option Group" } },
              [
                _c("b-select-option", { attrs: { value: "3" } }, [
                  _vm._v("Option Three")
                ]),
                _c("b-select-option", { attrs: { value: "4" } }, [
                  _vm._v("Option Four")
                ])
              ],
              1
            ),
            _c("b-select-option", { attrs: { value: "3" } }, [
              _vm._v("Option Five")
            ]),
            _c("b-select-option", { attrs: { value: "4" } }, [
              _vm._v("Option Six")
            ])
          ],
          1
        ),
        _c("label", { attrs: { for: "b-select-two" } }, [
          _vm._v("Long Select Box")
        ]),
        _c(
          "b-select",
          { attrs: { id: "b-select-two" } },
          [
            _c("b-select-option", { attrs: { value: "1" } }, [
              _vm._v("Something Pretty Long")
            ]),
            _c("b-select-option", { attrs: { value: "2" } }, [
              _vm._v("Option Two")
            ]),
            _c("b-select-option", { attrs: { value: "3" } }, [
              _vm._v("Option Three")
            ]),
            _c("b-select-option", { attrs: { value: "4" } }, [
              _vm._v("Option Four")
            ])
          ],
          1
        ),
        _c("label", { attrs: { for: "b-select-three" } }, [
          _vm._v("Short Select Box")
        ]),
        _c(
          "b-select",
          { attrs: { id: "b-select-three" } },
          [
            _c("b-select-option", { attrs: { value: "1" } }, [_vm._v("One")]),
            _c("b-select-option", { attrs: { value: "2" } }, [_vm._v("Two")]),
            _c("b-select-option", { attrs: { value: "3" } }, [_vm._v("Three")]),
            _c("b-select-option", { attrs: { value: "4" } }, [_vm._v("Four")])
          ],
          1
        ),
        _c("label", { attrs: { for: "b-select-four" } }, [
          _vm._v("Disabled Select Box")
        ]),
        _c(
          "b-select",
          { attrs: { id: "b-select-four", disabled: "", value: null } },
          [
            _c("b-select-option", { attrs: { value: null } }, [
              _vm._v("I'm Disabled")
            ]),
            _c("b-select-option", { attrs: { value: "2" } }, [_vm._v("Two")]),
            _c("b-select-option", { attrs: { value: "3" } }, [_vm._v("Three")]),
            _c("b-select-option", { attrs: { value: "4" } }, [_vm._v("Four")])
          ],
          1
        ),
        _c("label", [_vm._v("HTML Input Text")]),
        _c("input", { attrs: { type: "text", placeholder: "Text Input" } }),
        _c("label", [_vm._v("Disabled Input Text")]),
        _c("input", {
          attrs: { type: "text", disabled: "", placeholder: "Text Input" }
        }),
        _c("label", [_vm._v("HTML Input Number")]),
        _c("input", { attrs: { type: "number", placeholder: "Number Input" } }),
        _c("label", [_vm._v("Disabled Input Number")]),
        _c("input", {
          attrs: { type: "number", disabled: "", placeholder: "Number Input" }
        }),
        _c("label", [_vm._v("HTML Input Date")]),
        _c("input", { attrs: { type: "date", placeholder: "Date Input" } }),
        _c("label", [_vm._v("Disabled Date")]),
        _c("input", {
          attrs: { type: "date", disabled: "", placeholder: "Date Input" }
        }),
        _c("label", [_vm._v("Radio Boxes")]),
        _c("input", {
          attrs: { type: "radio", name: "filterRadio", id: "radio-one" }
        }),
        _c("label", { attrs: { for: "radio-one" } }, [_vm._v("Radio One")]),
        _c("input", {
          attrs: { type: "radio", name: "filterRadio", id: "radio-two" }
        }),
        _c("label", { attrs: { for: "radio-two" } }, [_vm._v("Radio Two")]),
        _c("input", {
          attrs: { type: "radio", name: "filterRadio", id: "radio-three" }
        }),
        _c("label", { attrs: { for: "radio-three" } }, [_vm._v("Radio Three")]),
        _c("label", [_vm._v("Standard Icons")]),
        _c("i", { staticClass: "fa fa-road" }),
        _c("i", { staticClass: "fa fa-map-marker" }),
        _c("i", { staticClass: "fa fa-car" }),
        _c("label", [_vm._v("Icon Buttons")]),
        _c("i", { staticClass: "icon-button fa fa-road" }),
        _c("i", { staticClass: "icon-button fa fa-map-marker warning" }),
        _c("i", { staticClass: "icon-button fa fa-car danger" }),
        _c("label", [_vm._v("Icon Buttons Bordered")]),
        _c("i", { staticClass: "icon-button bordered fa fa-road" }),
        _c("i", {
          staticClass: "icon-button bordered fa fa-map-marker warning"
        }),
        _c("i", { staticClass: "icon-button bordered fa fa-car danger" }),
        _c("label", [_vm._v("Default Buttons")]),
        _c("button", [_vm._v("Plain")]),
        _c("button", { attrs: { disabled: "" } }, [_vm._v("Disabled")]),
        _c("button", { staticClass: "success" }, [_vm._v("Success")]),
        _c("button", { staticClass: "warning" }, [_vm._v("Warning")]),
        _c("button", { staticClass: "danger" }, [_vm._v("Danger")]),
        _c("button", { staticClass: "success", attrs: { disabled: "" } }, [
          _vm._v("Success Disabled")
        ]),
        _c("button", { staticClass: "warning", attrs: { disabled: "" } }, [
          _vm._v("Warning Disabled")
        ]),
        _c("button", { staticClass: "danger", attrs: { disabled: "" } }, [
          _vm._v("Danger Disabled")
        ]),
        _c("label", [_vm._v("Bootstrap Buttons")]),
        _c("b-button", [_vm._v("Bootstrap Button")]),
        _c("b-button", { attrs: { variant: "warning" } }, [_vm._v("Warning")]),
        _c("b-button", { attrs: { variant: "danger" } }, [_vm._v("Danger")]),
        _vm._m(0),
        _c("label", [_vm._v("Bootstrap Input Groups")]),
        _c(
          "b-input-group",
          { attrs: { prepend: "Pre", append: "Post" } },
          [
            _c("b-form-input", {
              attrs: {
                type: "text",
                maxlength: "256",
                placeholder: "Bootstrap Input"
              }
            })
          ],
          1
        ),
        _c("label", [_vm._v("Bootstrap Input Group Range")]),
        _c(
          "b-input-group",
          { attrs: { prepend: "Pre", append: "Post" } },
          [
            _c("b-form-input", {
              attrs: {
                type: "range",
                maxlength: "256",
                placeholder: "Bootstrap Input"
              }
            })
          ],
          1
        ),
        _c("label", [_vm._v("Bootstrap Input Group Range Disabled")]),
        _c(
          "b-input-group",
          { attrs: { prepend: "Pre", append: "Post" } },
          [
            _c("b-form-input", {
              attrs: {
                disabled: "",
                type: "range",
                maxlength: "256",
                placeholder: "Bootstrap Input"
              }
            })
          ],
          1
        )
      ],
      1
    ),
    _c("h1", [_vm._v("Col Layout")]),
    _c(
      "div",
      { staticClass: "flex-column z-10" },
      [
        _c("label", { attrs: { for: "b-select-one" } }, [
          _vm._v("BV Select Box")
        ]),
        _c(
          "b-select",
          { attrs: { id: "b-select-one" } },
          [
            _c("b-select-option", { attrs: { value: "1", disabled: "" } }, [
              _vm._v("Disabled Opt")
            ]),
            _c("b-select-option", { attrs: { value: "2" } }, [
              _vm._v("Option Two")
            ]),
            _c("b-select-option", { attrs: { value: "3" } }, [
              _vm._v("Option Three")
            ]),
            _c("b-select-option", { attrs: { value: "4" } }, [
              _vm._v("Option Four")
            ])
          ],
          1
        ),
        _c("label", { attrs: { for: "b-select-two" } }, [
          _vm._v("Long Select Box")
        ]),
        _c(
          "b-select",
          { attrs: { id: "b-select-two" } },
          [
            _c("b-select-option", { attrs: { value: "1" } }, [
              _vm._v("Something Pretty Long")
            ]),
            _c("b-select-option", { attrs: { value: "2" } }, [
              _vm._v("Option Two")
            ]),
            _c("b-select-option", { attrs: { value: "3" } }, [
              _vm._v("Option Three")
            ]),
            _c("b-select-option", { attrs: { value: "4" } }, [
              _vm._v("Option Four")
            ])
          ],
          1
        ),
        _c("label", { attrs: { for: "b-select-three" } }, [
          _vm._v("Short Select Box")
        ]),
        _c(
          "b-select",
          { attrs: { id: "b-select-three" } },
          [
            _c("b-select-option", { attrs: { value: "1" } }, [_vm._v("One")]),
            _c("b-select-option", { attrs: { value: "2" } }, [_vm._v("Two")]),
            _c("b-select-option", { attrs: { value: "3" } }, [_vm._v("Three")]),
            _c("b-select-option", { attrs: { value: "4" } }, [_vm._v("Four")])
          ],
          1
        ),
        _c("label", { attrs: { for: "b-select-four" } }, [
          _vm._v("Disabled Select Box")
        ]),
        _c(
          "b-select",
          { attrs: { id: "b-select-four", disabled: "", value: null } },
          [
            _c("b-select-option", { attrs: { value: null } }, [
              _vm._v("I'm Disabled")
            ]),
            _c("b-select-option", { attrs: { value: "2" } }, [_vm._v("Two")]),
            _c("b-select-option", { attrs: { value: "3" } }, [_vm._v("Three")]),
            _c("b-select-option", { attrs: { value: "4" } }, [_vm._v("Four")])
          ],
          1
        ),
        _c("label", [_vm._v("HTML Input Text")]),
        _c("input", { attrs: { type: "text", placeholder: "Text Input" } }),
        _c("label", [_vm._v("Disabled Input Text")]),
        _c("input", {
          attrs: { type: "text", disabled: "", placeholder: "Text Input" }
        }),
        _c("label", [_vm._v("HTML Input Number")]),
        _c("input", { attrs: { type: "number", placeholder: "Number Input" } }),
        _c("label", [_vm._v("Disabled Input Number")]),
        _c("input", {
          attrs: { type: "number", disabled: "", placeholder: "Number Input" }
        }),
        _c("label", [_vm._v("HTML Input Date")]),
        _c("input", { attrs: { type: "date", placeholder: "Date Input" } }),
        _c("label", [_vm._v("Disabled Date")]),
        _c("input", {
          attrs: { type: "date", disabled: "", placeholder: "Date Input" }
        }),
        _c("label", [_vm._v("Radio Boxes")]),
        _c("input", {
          attrs: { type: "radio", name: "filterRadio", id: "radio-one" }
        }),
        _c("label", { attrs: { for: "radio-one" } }, [_vm._v("Radio One")]),
        _c("input", {
          attrs: { type: "radio", name: "filterRadio", id: "radio-two" }
        }),
        _c("label", { attrs: { for: "radio-two" } }, [_vm._v("Radio Two")]),
        _c("input", {
          attrs: { type: "radio", name: "filterRadio", id: "radio-three" }
        }),
        _c("label", { attrs: { for: "radio-three" } }, [_vm._v("Radio Three")]),
        _c("label", { attrs: { for: "radio-three" } }, [
          _vm._v("BootStrap Switch")
        ]),
        _c(
          "b-form-checkbox",
          { attrs: { size: "sm", name: "check-button", switch: "" } },
          [_vm._v(" Small Switch")]
        ),
        _c(
          "b-form-checkbox",
          { attrs: { size: "md", name: "check-button", switch: "" } },
          [_vm._v(" Medium Switch")]
        ),
        _c(
          "b-form-checkbox",
          { attrs: { size: "lg", name: "check-button", switch: "" } },
          [_vm._v(" Large Switch")]
        ),
        _c(
          "b-form-checkbox",
          { attrs: { size: "xl", name: "check-button", switch: "" } },
          [_vm._v(" XL Switch")]
        ),
        _c("label", [_vm._v("Standard Icons")]),
        _c("i", { staticClass: "fa fa-road" }),
        _c("i", { staticClass: "fa fa-map-marker" }),
        _c("i", { staticClass: "fa fa-car" }),
        _c("label", [_vm._v("Default Buttons")]),
        _c("button", [_vm._v("Plain")]),
        _c("button", { staticClass: "success" }, [_vm._v("Success")]),
        _c("button", { staticClass: "warning" }, [_vm._v("Warning")]),
        _c("button", { staticClass: "danger" }, [_vm._v("Danger")]),
        _c("button", { staticClass: "success", attrs: { disabled: "" } }, [
          _vm._v("Success Disabled")
        ]),
        _c("button", { staticClass: "warning", attrs: { disabled: "" } }, [
          _vm._v("Warning Disabled")
        ]),
        _c("button", { staticClass: "danger", attrs: { disabled: "" } }, [
          _vm._v("Danger Disabled")
        ]),
        _c("label", [_vm._v("Bootstrap Buttons")]),
        _c("b-button", [_vm._v("Bootstrap Button")]),
        _c("b-button", { attrs: { variant: "warning" } }, [_vm._v("Warning")]),
        _c("b-button", { attrs: { variant: "danger" } }, [_vm._v("Danger")]),
        _c("label", [_vm._v("Device Selector Single Default")]),
        _c(
          "div",
          { staticClass: "flex-row" },
          [
            _c("DeviceSelector", {
              model: {
                value: _vm.selectedDevices,
                callback: function($$v) {
                  _vm.selectedDevices = $$v
                },
                expression: "selectedDevices"
              }
            }),
            _vm._v(" ---> " + _vm._s(_vm.selectedDevices) + " ")
          ],
          1
        ),
        _c("label", [_vm._v("Device Selector Multi Default")]),
        _c(
          "div",
          { staticClass: "flex-row" },
          [
            _c("DeviceSelector", {
              attrs: { selection_mode: "multi" },
              model: {
                value: _vm.selectedDevicesMulti,
                callback: function($$v) {
                  _vm.selectedDevicesMulti = $$v
                },
                expression: "selectedDevicesMulti"
              }
            }),
            _vm._v(" ---> " + _vm._s(_vm.selectedDevicesMulti) + " ")
          ],
          1
        ),
        _c("label", [_vm._v("Device Selector Multi Def all and emit_on_load")]),
        _c(
          "div",
          { staticClass: "flex-row" },
          [
            _c("DeviceSelector", {
              attrs: { emit_on_load: "", selection_mode: "multi" },
              model: {
                value: _vm.selectedDevicesMultiAll,
                callback: function($$v) {
                  _vm.selectedDevicesMultiAll = $$v
                },
                expression: "selectedDevicesMultiAll"
              }
            }),
            _vm._v(" ---> " + _vm._s(_vm.selectedDevicesMultiAll) + " ")
          ],
          1
        ),
        _c("label", [_vm._v("Device Selector Multi Default")]),
        _c(
          "div",
          { staticClass: "flex-row" },
          [
            _c("DeviceSelector", {
              attrs: { selection_mode: "multi" },
              model: {
                value: _vm.selectedDevicesMultiDef,
                callback: function($$v) {
                  _vm.selectedDevicesMultiDef = $$v
                },
                expression: "selectedDevicesMultiDef"
              }
            }),
            _vm._v(" ---> " + _vm._s(_vm.selectedDevicesMultiDef) + " ")
          ],
          1
        ),
        _c("label", [_vm._v("Device Selector Multi Dropdown Mode")]),
        _c(
          "div",
          { staticClass: "flex-row" },
          [
            _c("DeviceSelector", {
              attrs: { selection_mode: "multi", dropdown_mode: "" },
              model: {
                value: _vm.selectedDevicesMultiDD,
                callback: function($$v) {
                  _vm.selectedDevicesMultiDD = $$v
                },
                expression: "selectedDevicesMultiDD"
              }
            }),
            _vm._v(" ---> " + _vm._s(_vm.selectedDevicesMultiDD) + " ")
          ],
          1
        ),
        _c("label", [
          _vm._v("Device Selector Multi Dropdown Default All emit_on_load")
        ]),
        _c(
          "div",
          { staticClass: "flex-row" },
          [
            _c("DeviceSelector", {
              attrs: {
                selection_mode: "multi",
                dropdown_mode: "",
                emit_on_load: ""
              },
              model: {
                value: _vm.selectedDevicesMultiDDall,
                callback: function($$v) {
                  _vm.selectedDevicesMultiDDall = $$v
                },
                expression: "selectedDevicesMultiDDall"
              }
            }),
            _vm._v(" ---> " + _vm._s(_vm.selectedDevicesMultiDDall) + " ")
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      [
        _c("h1", [_vm._v("BootStrap Tabs")]),
        _c(
          "b-tabs",
          [
            _c("b-tab", { attrs: { title: "First Tab" } }, [
              _c("div", { staticClass: "flex-row" }, [
                _c("div", { staticClass: "flex-column" }, [
                  _c("label", [_vm._v("Some Field")]),
                  _c("input", {
                    attrs: { type: "text", placeholder: "Enter Text" }
                  })
                ]),
                _c("div", { staticClass: "flex-column" }, [
                  _c("label", [_vm._v("Some Field")]),
                  _c("input", { attrs: { type: "text" } })
                ])
              ]),
              _c("div", { staticClass: "flex-row" }, [
                _c("button", [_vm._v("OK")]),
                _c("button", [_vm._v("Cancel")])
              ])
            ]),
            _c("b-tab", { attrs: { title: "Second Tab" } }, [
              _vm._v(" Some Tab content ")
            ]),
            _c("b-tab", { attrs: { title: "Disabled Tab", disabled: "" } }, [
              _vm._v(" Some Tab content ")
            ])
          ],
          1
        )
      ],
      1
    ),
    _c("div", [
      _c("label", [_vm._v("Trip Tags")]),
      _c(
        "div",
        { staticClass: "flex-row" },
        [
          _c("TripTags", {
            model: {
              value: _vm.testTags,
              callback: function($$v) {
                _vm.testTags = $$v
              },
              expression: "testTags"
            }
          }),
          _vm._v(" ---> " + _vm._s(_vm.testTags) + " ")
        ],
        1
      )
    ]),
    _c("div", [
      _c("label", [_vm._v("Trip Tags Editable")]),
      _c(
        "div",
        { staticClass: "flex-row" },
        [
          _c("TripTags", {
            attrs: { editable: "" },
            model: {
              value: _vm.testTags,
              callback: function($$v) {
                _vm.testTags = $$v
              },
              expression: "testTags"
            }
          }),
          _vm._v(" ---> " + _vm._s(_vm.testTags) + " ")
        ],
        1
      )
    ]),
    _c(
      "div",
      [
        _c("h3", [_vm._v(" Avatars")]),
        _c("div", [
          _c(
            "button",
            {
              on: {
                click: function($event) {
                  _vm.selectAvatars = !_vm.selectAvatars
                }
              }
            },
            [_vm._v("Toggle Select Avatars")]
          ),
          _vm._v(" Selected: " + _vm._s(_vm.selectAvatars))
        ]),
        _c("div", [
          _c(
            "button",
            {
              on: {
                click: function($event) {
                  _vm.disableAvatars = !_vm.disableAvatars
                }
              }
            },
            [_vm._v("Toggle Disable Avatars")]
          ),
          _vm._v(" Disabled: " + _vm._s(_vm.disableAvatars) + " ")
        ]),
        _vm._v(" Device With Link: "),
        _c("DeviceAvatar", {
          attrs: {
            device: _vm.testDeviceOn,
            enable_link: "",
            disabled: _vm.disableAvatars,
            selected: _vm.selectAvatars
          }
        }),
        _vm._v(" Device No Indicators: "),
        _c("DeviceAvatar", {
          attrs: {
            device: _vm.testDeviceOn,
            disabled: _vm.disableAvatars,
            selected: _vm.selectAvatars
          }
        }),
        _vm._v(" Device Acc On: "),
        _c("DeviceAvatar", {
          attrs: {
            device: _vm.testDeviceOn,
            "show-indicators": "",
            disabled: _vm.disableAvatars,
            selected: _vm.selectAvatars
          }
        }),
        _vm._v(" Device Acc Off: "),
        _c("DeviceAvatar", {
          attrs: {
            device: _vm.testDeviceOff,
            "show-indicators": "",
            disabled: _vm.disableAvatars,
            selected: _vm.selectAvatars
          }
        }),
        _vm._v(" Object Tracker: "),
        _c("DeviceAvatar", {
          attrs: {
            device: _vm.testDeviceObjectTracker,
            "show-indicators": "",
            disabled: _vm.disableAvatars,
            selected: _vm.selectAvatars
          }
        }),
        _vm._v(" User: "),
        _c("UserAvatar", {
          attrs: {
            user: _vm.testUserData,
            disabled: _vm.disableAvatars,
            selected: _vm.selectAvatars
          }
        }),
        _vm._v(" Trips: "),
        _c("TripAvatar", {
          attrs: {
            trip: _vm.testTripMoving,
            disabled: _vm.disableAvatars,
            selected: _vm.selectAvatars
          }
        }),
        _c("TripAvatar", {
          attrs: {
            trip: _vm.testTripParked,
            disabled: _vm.disableAvatars,
            selected: _vm.selectAvatars
          }
        }),
        _c("TripAvatar", {
          attrs: {
            trip: _vm.testTripForms,
            disabled: _vm.disableAvatars,
            selected: _vm.selectAvatars
          }
        }),
        _c("TripAvatar", {
          attrs: {
            trip: _vm.testTripNotes,
            disabled: _vm.disableAvatars,
            selected: _vm.selectAvatars
          }
        }),
        _c("TripAvatar", {
          attrs: {
            trip: _vm.testTripBoth,
            disabled: _vm.disableAvatars,
            selected: _vm.selectAvatars
          }
        }),
        _c("TripAvatar", {
          attrs: {
            trip: _vm.testTripBoth,
            disabled: _vm.disableAvatars,
            selected: _vm.selectAvatars,
            show_address: ""
          }
        }),
        _vm._v(" Form Data: "),
        _c("FormDataAvatar", {
          attrs: {
            form: _vm.tripFormsOne[0],
            disabled: _vm.disableAvatars,
            selected: _vm.selectAvatars
          }
        }),
        _vm._v(" Another Example: "),
        _c("FormDataAvatar", {
          attrs: {
            form: _vm.tripFormsOne[1],
            disabled: _vm.disableAvatars,
            selected: _vm.selectAvatars
          }
        })
      ],
      1
    ),
    _c(
      "div",
      [
        _c("label", [_vm._v("Date Selected")]),
        _c("DateSelector", {
          model: {
            value: _vm.selectedDate,
            callback: function($$v) {
              _vm.selectedDate = $$v
            },
            expression: "selectedDate"
          }
        }),
        _vm._v(" " + _vm._s(_vm.selectedDate) + " ")
      ],
      1
    ),
    _c(
      "div",
      [
        _c("label", [_vm._v("FA Icon Selector")]),
        _c("IconSelector", {
          model: {
            value: _vm.selectedIcon,
            callback: function($$v) {
              _vm.selectedIcon = $$v
            },
            expression: "selectedIcon"
          }
        }),
        _vm._v(" --> " + _vm._s(_vm.selectedIcon) + " ")
      ],
      1
    ),
    _c(
      "div",
      [
        _c("label", [_vm._v("Coloured FA Icon Selector")]),
        _c("IconSelector", {
          attrs: { color: "green" },
          model: {
            value: _vm.selectedIcon,
            callback: function($$v) {
              _vm.selectedIcon = $$v
            },
            expression: "selectedIcon"
          }
        }),
        _vm._v(" --> " + _vm._s(_vm.selectedIcon) + " ")
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "w-100" },
      [
        _c("label", [_vm._v("Workflow Progress Widget - 3 Steps")]),
        _c("WorkflowProgressWidget", {
          attrs: { steps: _vm.workflowSteps, progress: _vm.workflowProgress }
        }),
        _vm._v(" Progress: " + _vm._s(_vm.workflowProgress) + " "),
        _c(
          "button",
          {
            on: {
              click: function($event) {
                _vm.workflowProgress++
              }
            }
          },
          [_vm._v("Add")]
        ),
        _c(
          "button",
          {
            on: {
              click: function($event) {
                _vm.workflowProgress--
              }
            }
          },
          [_vm._v("Remove")]
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "w-100" },
      [
        _c("label", [_vm._v("Workflow Progress Widget - 4 Steps")]),
        _c("WorkflowProgressWidget", {
          attrs: {
            steps: _vm.workflowStepsTwo,
            progress: _vm.workflowProgressTwo
          }
        }),
        _vm._v(" Progress: " + _vm._s(_vm.workflowProgressTwo) + " "),
        _c(
          "button",
          {
            on: {
              click: function($event) {
                _vm.workflowProgressTwo++
              }
            }
          },
          [_vm._v("Add")]
        ),
        _c(
          "button",
          {
            on: {
              click: function($event) {
                _vm.workflowProgressTwo--
              }
            }
          },
          [_vm._v("Remove")]
        )
      ],
      1
    ),
    _c("h4", [_vm._v("Inline Form List (For Trips, etc)")]),
    _c(
      "div",
      [
        _c("FormListInline", {
          attrs: { editable: "" },
          model: {
            value: _vm.tripFormsOne,
            callback: function($$v) {
              _vm.tripFormsOne = $$v
            },
            expression: "tripFormsOne"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-row slim-controls" }, [
      _c("label", [_vm._v("Slim Controls")]),
      _c("button", [_vm._v("Plain")]),
      _c("button", { staticClass: "success" }, [_vm._v("Success")]),
      _c("button", { staticClass: "warning" }, [_vm._v("Warning")]),
      _c("button", { staticClass: "danger" }, [_vm._v("Danger")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }