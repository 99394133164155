<template>
  <div class="test-forms">
    <h1>Row Layout</h1>
    <div class="flex-row">
      <label for="b-select-one">BV Select Box</label>
      <b-select id="b-select-one">
        <b-select-option value="1" disabled>Disabled Opt</b-select-option>
        <b-select-option value="2">Option Two</b-select-option>
        <b-select-option-group label="Option Group">
          <b-select-option value="3">Option Three</b-select-option>
          <b-select-option value="4">Option Four</b-select-option>
        </b-select-option-group>
        <b-select-option value="3">Option Five</b-select-option>
        <b-select-option value="4">Option Six</b-select-option>
      </b-select>
      <label for="b-select-two">Long Select Box</label>
      <b-select id="b-select-two">
        <b-select-option value="1">Something Pretty Long</b-select-option>
        <b-select-option value="2">Option Two</b-select-option>
        <b-select-option value="3">Option Three</b-select-option>
        <b-select-option value="4">Option Four</b-select-option>
      </b-select>
      <label for="b-select-three">Short Select Box</label>
      <b-select id="b-select-three">
        <b-select-option value="1">One</b-select-option>
        <b-select-option value="2">Two</b-select-option>
        <b-select-option value="3">Three</b-select-option>
        <b-select-option value="4">Four</b-select-option>
      </b-select>
      <label for="b-select-four">Disabled Select Box</label>
      <b-select id="b-select-four" disabled :value="null">
        <b-select-option :value="null">I'm Disabled</b-select-option>
        <b-select-option value="2">Two</b-select-option>
        <b-select-option value="3">Three</b-select-option>
        <b-select-option value="4">Four</b-select-option>
      </b-select>
      <label>HTML Input Text</label>
      <input type="text" placeholder="Text Input">
      <label>Disabled Input Text</label>
      <input type="text" disabled placeholder="Text Input">
      <label>HTML Input Number</label>
      <input type="number" placeholder="Number Input">
      <label>Disabled Input Number</label>
      <input type="number" disabled placeholder="Number Input">
      <label>HTML Input Date</label>
      <input type="date" placeholder="Date Input">
      <label>Disabled Date</label>
      <input type="date" disabled placeholder="Date Input">
      <label>Radio Boxes</label>
      <input type="radio" name="filterRadio" id="radio-one">
      <label for="radio-one">Radio One</label>
      <input type="radio" name="filterRadio" id="radio-two">
      <label for="radio-two">Radio Two</label>
      <input type="radio" name="filterRadio" id="radio-three">
      <label for="radio-three">Radio Three</label>
      <label>Standard Icons</label>
      <i class="fa fa-road"></i>
      <i class="fa fa-map-marker"></i>
      <i class="fa fa-car"></i>

      <label>Icon Buttons</label>
      <i class="icon-button fa fa-road"></i>
      <i class="icon-button fa fa-map-marker warning"></i>
      <i class="icon-button fa fa-car danger"></i>

      <label>Icon Buttons Bordered</label>
      <i class="icon-button bordered fa fa-road"></i>
      <i class="icon-button bordered fa fa-map-marker warning"></i>
      <i class="icon-button bordered fa fa-car danger"></i>

      <label>Default Buttons</label>
      <button>Plain</button>
      <button disabled>Disabled</button>
      <button class="success">Success</button>
      <button class="warning">Warning</button>
      <button class="danger">Danger</button>
      <button disabled class="success">Success Disabled</button>
      <button disabled class="warning">Warning Disabled</button>
      <button disabled class="danger">Danger Disabled</button>
      <label>Bootstrap Buttons</label>
      <b-button>Bootstrap Button</b-button>
      <b-button variant="warning">Warning</b-button>
      <b-button variant="danger">Danger</b-button>
      <div class="flex-row slim-controls">
        <label>Slim Controls</label>
        <button>Plain</button>
        <button class="success">Success</button>
        <button class="warning">Warning</button>
        <button class="danger">Danger</button>
      </div>
      <label>Bootstrap Input Groups</label>
      <b-input-group prepend="Pre" append="Post">
        <b-form-input
          type="text"
          maxlength="256" placeholder="Bootstrap Input"
        />
      </b-input-group>
      <label>Bootstrap Input Group Range</label>
      <b-input-group prepend="Pre" append="Post">
        <b-form-input
          type="range"
          maxlength="256" placeholder="Bootstrap Input"
        />
      </b-input-group>
      <label>Bootstrap Input Group Range Disabled</label>
      <b-input-group prepend="Pre" append="Post">
        <b-form-input disabled
          type="range"
          maxlength="256" placeholder="Bootstrap Input"
        />
      </b-input-group>

    </div>

    <h1>Col Layout</h1>
    <div class="flex-column z-10">
      <label for="b-select-one">BV Select Box</label>
      <b-select id="b-select-one">
        <b-select-option value="1" disabled>Disabled Opt</b-select-option>
        <b-select-option value="2">Option Two</b-select-option>
        <b-select-option value="3">Option Three</b-select-option>
        <b-select-option value="4">Option Four</b-select-option>
      </b-select>
      <label for="b-select-two">Long Select Box</label>
      <b-select id="b-select-two">
        <b-select-option value="1">Something Pretty Long</b-select-option>
        <b-select-option value="2">Option Two</b-select-option>
        <b-select-option value="3">Option Three</b-select-option>
        <b-select-option value="4">Option Four</b-select-option>
      </b-select>
      <label for="b-select-three">Short Select Box</label>
      <b-select id="b-select-three">
        <b-select-option value="1">One</b-select-option>
        <b-select-option value="2">Two</b-select-option>
        <b-select-option value="3">Three</b-select-option>
        <b-select-option value="4">Four</b-select-option>
      </b-select>
      <label for="b-select-four">Disabled Select Box</label>
      <b-select id="b-select-four" disabled :value="null">
        <b-select-option :value="null">I'm Disabled</b-select-option>
        <b-select-option value="2">Two</b-select-option>
        <b-select-option value="3">Three</b-select-option>
        <b-select-option value="4">Four</b-select-option>
      </b-select>

      <label>HTML Input Text</label>
      <input type="text" placeholder="Text Input">
      <label>Disabled Input Text</label>
      <input type="text" disabled placeholder="Text Input">
      <label>HTML Input Number</label>
      <input type="number" placeholder="Number Input">
      <label>Disabled Input Number</label>
      <input type="number" disabled placeholder="Number Input">
      <label>HTML Input Date</label>
      <input type="date" placeholder="Date Input">
      <label>Disabled Date</label>
      <input type="date" disabled placeholder="Date Input">
      <label>Radio Boxes</label>
      <input type="radio" name="filterRadio" id="radio-one">
      <label for="radio-one">Radio One</label>
      <input type="radio" name="filterRadio" id="radio-two">
      <label for="radio-two">Radio Two</label>
      <input type="radio" name="filterRadio" id="radio-three">
      <label for="radio-three">Radio Three</label>
      <label for="radio-three">BootStrap Switch</label>
      <b-form-checkbox size="sm" name="check-button" switch >
        Small Switch</b-form-checkbox>
      <b-form-checkbox size="md" name="check-button" switch >
        Medium Switch</b-form-checkbox>
      <b-form-checkbox size="lg" name="check-button" switch >
        Large Switch</b-form-checkbox>
      <b-form-checkbox size="xl" name="check-button" switch >
        XL Switch</b-form-checkbox>
      <label>Standard Icons</label>
      <i class="fa fa-road"></i>
      <i class="fa fa-map-marker"></i>
      <i class="fa fa-car"></i>
      <label>Default Buttons</label>
      <button>Plain</button>
      <button class="success">Success</button>
      <button class="warning">Warning</button>
      <button class="danger">Danger</button>
      <button disabled class="success">Success Disabled</button>
      <button disabled class="warning">Warning Disabled</button>
      <button disabled class="danger">Danger Disabled</button>
      <label>Bootstrap Buttons</label>
      <b-button>Bootstrap Button</b-button>
      <b-button variant="warning">Warning</b-button>
      <b-button variant="danger">Danger</b-button>
      <label>Device Selector Single Default</label>
      <div class="flex-row">
        <DeviceSelector v-model="selectedDevices"></DeviceSelector> ---> {{selectedDevices}}
      </div>
      <label>Device Selector Multi Default</label>
      <div class="flex-row">
        <DeviceSelector v-model="selectedDevicesMulti" selection_mode="multi"></DeviceSelector> ---> {{selectedDevicesMulti}}
      </div>
      <label>Device Selector Multi Def all and emit_on_load</label>
      <div class="flex-row">
        <DeviceSelector v-model="selectedDevicesMultiAll" emit_on_load selection_mode="multi"></DeviceSelector> ---> {{selectedDevicesMultiAll}}
      </div>
      <label>Device Selector Multi Default</label>
      <div class="flex-row">
        <DeviceSelector v-model="selectedDevicesMultiDef" selection_mode="multi"></DeviceSelector> ---> {{selectedDevicesMultiDef}}
      </div>
      <label>Device Selector Multi Dropdown Mode</label>
      <div class="flex-row">
        <DeviceSelector v-model="selectedDevicesMultiDD" selection_mode="multi" dropdown_mode></DeviceSelector> ---> {{selectedDevicesMultiDD}}
      </div>
      <label>Device Selector Multi Dropdown Default All emit_on_load</label>
      <div class="flex-row">
        <DeviceSelector v-model="selectedDevicesMultiDDall" selection_mode="multi" dropdown_mode emit_on_load></DeviceSelector> ---> {{selectedDevicesMultiDDall}}
      </div>
    </div>
    <div>
      <h1>BootStrap Tabs</h1>
      <b-tabs>
        <b-tab title="First Tab">
          <div class="flex-row">
            <div class="flex-column">
              <label>Some Field</label>
              <input type="text" placeholder="Enter Text">
            </div>
            <div class="flex-column">
              <label>Some Field</label>
              <input type="text">
            </div>
          </div>
          <div class="flex-row">
            <button>OK</button>
            <button>Cancel</button>
          </div>
        </b-tab>
        <b-tab title="Second Tab">
          Some Tab content
        </b-tab>
        <b-tab title="Disabled Tab" disabled>
          Some Tab content
        </b-tab>
      </b-tabs>
    </div>
    <div>
      <label>Trip Tags</label>
      <div class="flex-row">
        <TripTags v-model="testTags"></TripTags> ---> {{testTags}}
      </div>
    </div>
    <div>
      <label>Trip Tags Editable</label>
      <div class="flex-row">
        <TripTags v-model="testTags" editable></TripTags> ---> {{testTags}}
      </div>
    </div>
    <div>
      <h3> Avatars</h3>
      <div>
        <button @click="selectAvatars = !selectAvatars">Toggle Select Avatars</button>
        Selected: {{selectAvatars}}</div>
      <div>
        <button @click="disableAvatars = !disableAvatars">Toggle Disable Avatars</button>
        Disabled: {{disableAvatars}}
      </div>
      Device With Link:
      <DeviceAvatar :device="testDeviceOn" enable_link :disabled="disableAvatars" :selected="selectAvatars"></DeviceAvatar>
      Device No Indicators:
      <DeviceAvatar :device="testDeviceOn" :disabled="disableAvatars" :selected="selectAvatars"></DeviceAvatar>
      Device Acc On:
      <DeviceAvatar :device="testDeviceOn" show-indicators :disabled="disableAvatars" :selected="selectAvatars"></DeviceAvatar>
      Device Acc Off:
      <DeviceAvatar :device="testDeviceOff" show-indicators :disabled="disableAvatars" :selected="selectAvatars"></DeviceAvatar>
      Object Tracker:
      <DeviceAvatar :device="testDeviceObjectTracker" show-indicators :disabled="disableAvatars" :selected="selectAvatars"> </DeviceAvatar>
      User:
      <UserAvatar :user="testUserData" :disabled="disableAvatars" :selected="selectAvatars"></UserAvatar>
      Trips:
      <TripAvatar :trip="testTripMoving" :disabled="disableAvatars" :selected="selectAvatars"></TripAvatar>
      <TripAvatar :trip="testTripParked" :disabled="disableAvatars" :selected="selectAvatars"></TripAvatar>
      <TripAvatar :trip="testTripForms" :disabled="disableAvatars" :selected="selectAvatars"></TripAvatar>
      <TripAvatar :trip="testTripNotes" :disabled="disableAvatars" :selected="selectAvatars"></TripAvatar>
      <TripAvatar :trip="testTripBoth" :disabled="disableAvatars" :selected="selectAvatars"></TripAvatar>
      <TripAvatar :trip="testTripBoth" :disabled="disableAvatars" :selected="selectAvatars" show_address></TripAvatar>
      Form Data:
      <FormDataAvatar :form="tripFormsOne[0]" :disabled="disableAvatars" :selected="selectAvatars"></FormDataAvatar>
      Another Example:
      <FormDataAvatar :form="tripFormsOne[1]" :disabled="disableAvatars" :selected="selectAvatars"></FormDataAvatar>
    </div>
    <div>
      <label>Date Selected</label>
      <DateSelector v-model="selectedDate"></DateSelector>
      {{selectedDate}}
    </div>
    <div>
      <label>FA Icon Selector</label>
      <IconSelector v-model="selectedIcon"></IconSelector> --> {{selectedIcon}}
    </div>
    <div>
      <label>Coloured FA Icon Selector</label>
      <IconSelector v-model="selectedIcon" color="green"></IconSelector> --> {{selectedIcon}}
    </div>
    <div class="w-100">
      <label>Workflow Progress Widget - 3 Steps</label>
      <WorkflowProgressWidget :steps="workflowSteps" :progress="workflowProgress"></WorkflowProgressWidget>
      Progress: {{workflowProgress}}
      <button @click="workflowProgress ++">Add</button>
      <button @click="workflowProgress --">Remove</button>
    </div>
    <div class="w-100">
      <label>Workflow Progress Widget - 4 Steps</label>
      <WorkflowProgressWidget :steps="workflowStepsTwo" :progress="workflowProgressTwo"></WorkflowProgressWidget>
      Progress: {{workflowProgressTwo}}
      <button @click="workflowProgressTwo ++">Add</button>
      <button @click="workflowProgressTwo --">Remove</button>
    </div>
    <h4>Inline Form List (For Trips, etc)</h4>
    <div>
      <FormListInline v-model="tripFormsOne" editable></FormListInline>
    </div>
  </div>
</template>

<script>
/***
 * Test Page for Form Controls
 *
 *  This page contains copies of most of the common form control is various different layouts and sizes.
 *  The forms controls on this page are examples of how these controls will look on the rest of the site.
 */
import DeviceSelector from '@/components/shared/DeviceSelector.vue'
import TripTags from '@/components/trips/TripTags.vue'
import UserAvatar from '@/components/users/UserAvatar.vue'
import DateSelector from '@/components/shared/DateSelector.vue'
import IconSelector from '@/components/shared/IconSelector.vue'
import WorkflowProgressWidget from '@/components/forms/WorkflowProgressWidget.vue'
import FormDataAvatar from '@/components/forms/FormDataAvatar.vue'
import DeviceAvatar from '@/components/device/DeviceAvatar.vue'
import TripAvatar from '@/components/trips/TripAvatar.vue'
import FormListInline from '@/components/forms/FromListInline.vue'

export default {
  name: 'test-forms-view',
  components: {
    FormListInline,
    TripAvatar,
    DeviceAvatar,
    FormDataAvatar,
    WorkflowProgressWidget,
    IconSelector,
    DateSelector,
    UserAvatar,
    TripTags,
    DeviceSelector
  },
  data () {
    return {
      selectedDevices: null,
      selectedDevicesMulti: null,
      selectedDevicesMultiAll: 'all',
      selectedDevicesMultiDef: ['123412341234123', '213LUL2020000004'],
      selectedDevicesMultiDD: [],
      selectedDevicesMultiDDall: 'all',
      disableAvatars: false,
      selectAvatars: false,
      testDeviceOn: {
        'device_code': 'A0000004',
        'device_id': 15,
        'device_imei': '213LUL2020000004',
        'device_name': '213LU Test #41',
        'device_type': 'device_sinocastel_213lu',
        'has_subscription': true,
        'icon': 'Car',
        'location': {
          'data': {
            'acc_on': true,
            'direction': 3320,
            'odometer_reading': 516652,
            'speed': 0,
            'speed_cm_sec': 0
          },
          'datetime': '2023-10-25T23:23:32+00:00',
          'device_imei': '213LUL2020000004',
          'latitude': -37.5629,
          'location_address': {
            'created': '2023-09-07T06:53:04',
            'data': {
              'address_text': '219 York St, Ballarat East VIC 3350, Australia',
              'geocode_result': {
                'address_components': [
                  {
                    'long_name': '219',
                    'short_name': '219',
                    'types': [
                      'street_number'
                    ]
                  },
                  {
                    'long_name': 'York Street',
                    'short_name': 'York St',
                    'types': [
                      'route'
                    ]
                  },
                  {
                    'long_name': 'Ballarat East',
                    'short_name': 'Ballarat East',
                    'types': [
                      'locality',
                      'political'
                    ]
                  },
                  {
                    'long_name': 'City of Ballarat',
                    'short_name': 'City of Ballarat',
                    'types': [
                      'administrative_area_level_2',
                      'political'
                    ]
                  },
                  {
                    'long_name': 'Victoria',
                    'short_name': 'VIC',
                    'types': [
                      'administrative_area_level_1',
                      'political'
                    ]
                  },
                  {
                    'long_name': 'Australia',
                    'short_name': 'AU',
                    'types': [
                      'country',
                      'political'
                    ]
                  },
                  {
                    'long_name': '3350',
                    'short_name': '3350',
                    'types': [
                      'postal_code'
                    ]
                  }
                ],
                'formatted_address': '219 York St, Ballarat East VIC 3350, Australia',
                'geometry': {
                  'location': {
                    'lat': -37.5686553,
                    'lng': 143.8741476
                  },
                  'location_type': 'ROOFTOP',
                  'viewport': {
                    'northeast': {
                      'lat': -37.5673063197085,
                      'lng': 143.8754965802915
                    },
                    'southwest': {
                      'lat': -37.5700042802915,
                      'lng': 143.8727986197085
                    }
                  }
                },
                'place_id': 'ChIJEdDlHwdF0WoRfJBVKw3SIKk',
                'plus_code': {
                  'compound_code': 'CVJF+GM Ballarat East VIC, Australia',
                  'global_code': '4RJ5CVJF+GM'
                },
                'types': [
                  'street_address'
                ]
              }
            },
            'id': 892,
            'location': {
              'lat': -37.5686553,
              'lng': 143.8741476
            },
            'location_type': 'address'
          },
          'location_id': 7,
          'longitude': 143.86644,
          'speed': '0 km/h',
          'timestamp': 1698276212
        },
        'plant_num': 'ROB-RAV4',
        'vehicle_dtcs': null
      },
      testDeviceOff: {
        'device_code': 'A0000004',
        'device_id': 15,
        'device_imei': '213LUL2020000004',
        'device_name': '213LU Test #41',
        'device_type': 'device_sinocastel_213lu',
        'has_subscription': true,
        'icon': 'Car',
        'location': {
          'data': {
            'acc_on': false,
            'direction': 3320,
            'odometer_reading': 516652,
            'speed': 0,
            'speed_cm_sec': 0
          },
          'datetime': '2023-10-25T23:23:32+00:00',
          'device_imei': '213LUL2020000004',
          'latitude': -37.5629,
          'location_address': {
            'created': '2023-09-07T06:53:04',
            'data': {
              'address_text': '219 York St, Ballarat East VIC 3350, Australia',
              'geocode_result': {
                'address_components': [
                  {
                    'long_name': '219',
                    'short_name': '219',
                    'types': [
                      'street_number'
                    ]
                  },
                  {
                    'long_name': 'York Street',
                    'short_name': 'York St',
                    'types': [
                      'route'
                    ]
                  },
                  {
                    'long_name': 'Ballarat East',
                    'short_name': 'Ballarat East',
                    'types': [
                      'locality',
                      'political'
                    ]
                  },
                  {
                    'long_name': 'City of Ballarat',
                    'short_name': 'City of Ballarat',
                    'types': [
                      'administrative_area_level_2',
                      'political'
                    ]
                  },
                  {
                    'long_name': 'Victoria',
                    'short_name': 'VIC',
                    'types': [
                      'administrative_area_level_1',
                      'political'
                    ]
                  },
                  {
                    'long_name': 'Australia',
                    'short_name': 'AU',
                    'types': [
                      'country',
                      'political'
                    ]
                  },
                  {
                    'long_name': '3350',
                    'short_name': '3350',
                    'types': [
                      'postal_code'
                    ]
                  }
                ],
                'formatted_address': '219 York St, Ballarat East VIC 3350, Australia',
                'geometry': {
                  'location': {
                    'lat': -37.5686553,
                    'lng': 143.8741476
                  },
                  'location_type': 'ROOFTOP',
                  'viewport': {
                    'northeast': {
                      'lat': -37.5673063197085,
                      'lng': 143.8754965802915
                    },
                    'southwest': {
                      'lat': -37.5700042802915,
                      'lng': 143.8727986197085
                    }
                  }
                },
                'place_id': 'ChIJEdDlHwdF0WoRfJBVKw3SIKk',
                'plus_code': {
                  'compound_code': 'CVJF+GM Ballarat East VIC, Australia',
                  'global_code': '4RJ5CVJF+GM'
                },
                'types': [
                  'street_address'
                ]
              }
            },
            'id': 892,
            'location': {
              'lat': -37.5686553,
              'lng': 143.8741476
            },
            'location_type': 'address'
          },
          'location_id': 7,
          'longitude': 143.86644,
          'speed': '0 km/h',
          'timestamp': 1698276212
        },
        'plant_num': 'ROB-RAV4',
        'vehicle_dtcs': null
      },
      testDeviceObjectTracker: {
        'device_battery_level': 65,
        'device_code': 'A8000562',
        'device_id': 37,
        'device_imei': '862112768000562',
        'device_name': 'Test KingWo',
        'device_type': 'device_kingwo_nt06e',
        'has_subscription': false,
        'icon': 'Container',
        'location': {
          'data': {
            'acc_on': false,
            'number_satellites': 16,
            'precise_positioning': true
          },
          'datetime': '2023-02-04T05:52:38',
          'device_imei': '862112768000562',
          'latitude': -37.65741,
          'location_id': 20,
          'longitude': 143.87902,
          'speed': 'N/A km/h',
          'timestamp': 1675489958
        },
        'plant_num': 'NT07e'
      },
      testTripMoving: {
        'annotation': null,
        'device_imei': '213LUL2020000004',
        'end_latitude': -37.56215,
        'end_location_address': {
          'created': '2023-08-17T05:02:52',
          'data': {
            'address_text': '53 Victoria St, Bakery Hill VIC 3350, Australia',
            'geocode_result': {
              'address_components': [
                {
                  'long_name': '53',
                  'short_name': '53',
                  'types': [
                    'street_number'
                  ]
                },
                {
                  'long_name': 'Victoria Street',
                  'short_name': 'Victoria St',
                  'types': [
                    'route'
                  ]
                },
                {
                  'long_name': 'Bakery Hill',
                  'short_name': 'Bakery Hill',
                  'types': [
                    'locality',
                    'political'
                  ]
                },
                {
                  'long_name': 'City of Ballarat',
                  'short_name': 'City of Ballarat',
                  'types': [
                    'administrative_area_level_2',
                    'political'
                  ]
                },
                {
                  'long_name': 'Victoria',
                  'short_name': 'VIC',
                  'types': [
                    'administrative_area_level_1',
                    'political'
                  ]
                },
                {
                  'long_name': 'Australia',
                  'short_name': 'AU',
                  'types': [
                    'country',
                    'political'
                  ]
                },
                {
                  'long_name': '3350',
                  'short_name': '3350',
                  'types': [
                    'postal_code'
                  ]
                }
              ],
              'formatted_address': '53 Victoria St, Bakery Hill VIC 3350, Australia',
              'geometry': {
                'bounds': {
                  'northeast': {
                    'lat': -37.5621042,
                    'lng': 143.8670506
                  },
                  'southwest': {
                    'lat': -37.5623086,
                    'lng': 143.8668352
                  }
                },
                'location': {
                  'lat': -37.5622057,
                  'lng': 143.8669533
                },
                'location_type': 'ROOFTOP',
                'viewport': {
                  'northeast': {
                    'lat': -37.5608574197085,
                    'lng': 143.8682918802915
                  },
                  'southwest': {
                    'lat': -37.5635553802915,
                    'lng': 143.8655939197085
                  }
                }
              },
              'place_id': 'ChIJBw1GNFVE0WoRHgfwoZtd3Ms',
              'types': [
                'premise'
              ]
            }
          },
          'id': 11,
          'location': {
            'lat': -37.5622057,
            'lng': 143.8669533
          },
          'location_type': 'address'
        },
        'end_longitude': 143.86688,
        'end_time': 1698272511,
        'events': [
          {
            'device_id': 15,
            'end_time': 1698268641,
            'event_data': {
              'alarm': {
                'alarm_description': '0200',
                'alarm_threshold': 0,
                'alarm_type': 22,
                'new_alarm_flag': 1
              },
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 1080,
                  'odometer_reading': 516616,
                  'speed': 0,
                  'speed_cm_sec': 0
                },
                'datetime': '2023-10-25T21:17:21+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 0,
                  'last_accon_time': '2023-10-25T21:17:21Z'
                },
                'latitude': -37.56863,
                'longitude': 143.8738,
                'timestamp': 1698268641
              }
            },
            'event_id': 119802,
            'event_subtype': 0,
            'event_type': 1002,
            'link': '/device/213LUL2020000004?event_id=119802',
            'source': 'system',
            'start_time': 1698268641
          },
          {
            'device_id': 15,
            'end_time': 1698268683,
            'event_data': {
              'alarm': {
                'alarm_description': '0300',
                'alarm_threshold': 0,
                'alarm_type': 23,
                'new_alarm_flag': 1
              },
              'location': {
                'data': {
                  'acc_on': false,
                  'direction': 3390,
                  'odometer_reading': 516616,
                  'speed': 0,
                  'speed_cm_sec': 0
                },
                'datetime': '2023-10-25T21:18:03+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 0,
                  'last_accon_time': '2023-10-25T21:17:21Z'
                },
                'latitude': -37.56861,
                'longitude': 143.87389,
                'timestamp': 1698268683
              }
            },
            'event_id': 119800,
            'event_subtype': 0,
            'event_type': 1003,
            'link': '/device/213LUL2020000004?event_id=119800',
            'source': 'system',
            'start_time': 1698268683
          },
          {
            'device_id': 15,
            'end_time': 1698268683,
            'event_data': {
              'alarm': {
                'alarm_description': '0300',
                'alarm_threshold': 0,
                'alarm_type': 23,
                'new_alarm_flag': 1
              },
              'location': {
                'data': {
                  'acc_on': false,
                  'direction': 3390,
                  'odometer_reading': 516616,
                  'speed': 0,
                  'speed_cm_sec': 0
                },
                'datetime': '2023-10-25T21:18:03+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 0,
                  'last_accon_time': '2023-10-25T21:17:21Z'
                },
                'latitude': -37.56861,
                'longitude': 143.87389,
                'timestamp': 1698268683
              }
            },
            'event_id': 119801,
            'event_subtype': 0,
            'event_type': 1003,
            'link': '/device/213LUL2020000004?event_id=119801',
            'source': 'system',
            'start_time': 1698268683
          },
          {
            'device_id': 15,
            'end_time': 1698268771,
            'event_data': {
              'alarm': {
                'alarm_description': '0200',
                'alarm_threshold': 0,
                'alarm_type': 22,
                'new_alarm_flag': 1
              },
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 3390,
                  'odometer_reading': 516616,
                  'speed': 0,
                  'speed_cm_sec': 0
                },
                'datetime': '2023-10-25T21:19:31+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 0,
                  'last_accon_time': '2023-10-25T21:19:31Z'
                },
                'latitude': -37.56861,
                'longitude': 143.87389,
                'timestamp': 1698268771
              }
            },
            'event_id': 119803,
            'event_subtype': 0,
            'event_type': 1002,
            'link': '/device/213LUL2020000004?event_id=119803',
            'source': 'system',
            'start_time': 1698268771
          },
          {
            'device_id': 15,
            'end_time': 1698268978,
            'event_data': {
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 1500,
                  'odometer_reading': 516620.598,
                  'overspeed_start': 1698268938,
                  'speed': 45,
                  'speed_cm_sec': 1255
                },
                'datetime': '2023-10-25T21:22:50+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 26,
                  'last_accon_time': '2023-10-25T21:19:31Z'
                },
                'latitude': -37.57821,
                'longitude': 143.87581,
                'timestamp': 1698268978
              },
              'speed': 45
            },
            'event_id': 119804,
            'event_subtype': 0,
            'event_type': 3002,
            'link': '/device/213LUL2020000004?event_id=119804',
            'source': 'system',
            'start_time': 1698268978
          },
          {
            'device_id': 15,
            'end_time': 1698269073,
            'event_data': {
              'alarm': {
                'alarm_description': '0100',
                'alarm_threshold': 1,
                'alarm_type': 6,
                'new_alarm_flag': 1
              },
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 1110,
                  'odometer_reading': 516621,
                  'speed': 0,
                  'speed_cm_sec': 0
                },
                'datetime': '2023-10-25T21:24:33+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 32,
                  'last_accon_time': '2023-10-25T21:19:31Z'
                },
                'latitude': -37.58054,
                'longitude': 143.87634,
                'timestamp': 1698269073
              }
            },
            'event_id': 119805,
            'event_subtype': 0,
            'event_type': 3007,
            'link': '/device/213LUL2020000004?event_id=119805',
            'source': 'system',
            'start_time': 1698269073
          },
          {
            'device_id': 15,
            'end_time': 1698269128,
            'event_data': {
              'alarm': {
                'alarm_description': '0100',
                'alarm_threshold': 1,
                'alarm_type': 6,
                'new_alarm_flag': 0
              },
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 1110,
                  'odometer_reading': 516621,
                  'speed': 0,
                  'speed_cm_sec': 0
                },
                'datetime': '2023-10-25T21:25:28+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 34,
                  'last_accon_time': '2023-10-25T21:19:31Z'
                },
                'latitude': -37.58054,
                'longitude': 143.87634,
                'timestamp': 1698269128
              }
            },
            'event_id': 119806,
            'event_subtype': 0,
            'event_type': 3007,
            'link': '/device/213LUL2020000004?event_id=119806',
            'source': 'system',
            'start_time': 1698269128
          },
          {
            'device_id': 15,
            'end_time': 1698269297,
            'event_data': {
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 1970,
                  'odometer_reading': 516620.598,
                  'overspeed_start': 1698269260,
                  'speed': 46,
                  'speed_cm_sec': 1305
                },
                'datetime': '2023-10-25T21:28:11+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 55,
                  'last_accon_time': '2023-10-25T21:19:31Z'
                },
                'latitude': -37.58648,
                'longitude': 143.86702,
                'timestamp': 1698269297
              },
              'speed': 46
            },
            'event_id': 119807,
            'event_subtype': 0,
            'event_type': 3002,
            'link': '/device/213LUL2020000004?event_id=119807',
            'source': 'system',
            'start_time': 1698269297
          },
          {
            'device_id': 15,
            'end_time': 1698269667,
            'event_data': {
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 1850,
                  'odometer_reading': 516620.598,
                  'overspeed_start': 1698269633,
                  'speed': 45,
                  'speed_cm_sec': 1263
                },
                'datetime': '2023-10-25T21:34:20+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 98,
                  'last_accon_time': '2023-10-25T21:19:31Z'
                },
                'latitude': -37.6051,
                'longitude': 143.84056,
                'timestamp': 1698269667
              },
              'speed': 45
            },
            'event_id': 119808,
            'event_subtype': 0,
            'event_type': 3002,
            'link': '/device/213LUL2020000004?event_id=119808',
            'source': 'system',
            'start_time': 1698269667
          },
          {
            'device_id': 15,
            'end_time': 1698270278,
            'event_data': {
              'alarm': {
                'alarm_description': '0100',
                'alarm_threshold': 1,
                'alarm_type': 6,
                'new_alarm_flag': 1
              },
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 380,
                  'odometer_reading': 516621,
                  'speed': 0,
                  'speed_cm_sec': 0
                },
                'datetime': '2023-10-25T21:44:38+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 170,
                  'last_accon_time': '2023-10-25T21:19:31Z'
                },
                'latitude': -37.6788,
                'longitude': 143.82694,
                'timestamp': 1698270278
              }
            },
            'event_id': 119809,
            'event_subtype': 0,
            'event_type': 3007,
            'link': '/device/213LUL2020000004?event_id=119809',
            'source': 'system',
            'start_time': 1698270278
          },
          {
            'device_id': 15,
            'end_time': 1698270293,
            'event_data': {
              'alarm': {
                'alarm_description': '0100',
                'alarm_threshold': 1,
                'alarm_type': 6,
                'new_alarm_flag': 0
              },
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 380,
                  'odometer_reading': 516621,
                  'speed': 0,
                  'speed_cm_sec': 0
                },
                'datetime': '2023-10-25T21:44:53+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 171,
                  'last_accon_time': '2023-10-25T21:19:31Z'
                },
                'latitude': -37.6788,
                'longitude': 143.82694,
                'timestamp': 1698270293
              }
            },
            'event_id': 119810,
            'event_subtype': 0,
            'event_type': 3007,
            'link': '/device/213LUL2020000004?event_id=119810',
            'source': 'system',
            'start_time': 1698270293
          },
          {
            'device_id': 15,
            'end_time': 1698270388,
            'event_data': {
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 50,
                  'odometer_reading': 516620.598,
                  'overspeed_start': 1698270356,
                  'speed': 55,
                  'speed_cm_sec': 1544
                },
                'datetime': '2023-10-25T21:46:25+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 180,
                  'last_accon_time': '2023-10-25T21:19:31Z'
                },
                'latitude': -37.67095,
                'longitude': 143.83191,
                'timestamp': 1698270388
              },
              'speed': 55
            },
            'event_id': 119811,
            'event_subtype': 0,
            'event_type': 3002,
            'link': '/device/213LUL2020000004?event_id=119811',
            'source': 'system',
            'start_time': 1698270388
          },
          {
            'device_id': 15,
            'end_time': 1698270751,
            'event_data': {
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 130,
                  'odometer_reading': 516620.598,
                  'overspeed_start': 1698270721,
                  'speed': 60,
                  'speed_cm_sec': 1691
                },
                'datetime': '2023-10-25T21:52:27+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 233,
                  'last_accon_time': '2023-10-25T21:19:31Z'
                },
                'latitude': -37.60954,
                'longitude': 143.83951,
                'timestamp': 1698270751
              },
              'speed': 60
            },
            'event_id': 119812,
            'event_subtype': 0,
            'event_type': 3002,
            'link': '/device/213LUL2020000004?event_id=119812',
            'source': 'system',
            'start_time': 1698270751
          },
          {
            'device_id': 15,
            'end_time': 1698271316,
            'event_data': {
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 540,
                  'odometer_reading': 516620.598,
                  'overspeed_start': 1698271286,
                  'speed': 55,
                  'speed_cm_sec': 1550
                },
                'datetime': '2023-10-25T22:01:50+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 275,
                  'last_accon_time': '2023-10-25T21:19:31Z'
                },
                'latitude': -37.56928,
                'longitude': 143.85177,
                'timestamp': 1698271316
              },
              'speed': 55
            },
            'event_id': 119813,
            'event_subtype': 0,
            'event_type': 3002,
            'link': '/device/213LUL2020000004?event_id=119813',
            'source': 'system',
            'start_time': 1698271316
          },
          {
            'device_id': 15,
            'end_time': 1698271754,
            'event_data': {
              'alarm': {
                'alarm_description': '0300',
                'alarm_threshold': 0,
                'alarm_type': 23,
                'new_alarm_flag': 1
              },
              'location': {
                'data': {
                  'acc_on': false,
                  'direction': 110,
                  'odometer_reading': 516621,
                  'speed': 0,
                  'speed_cm_sec': 0
                },
                'datetime': '2023-10-25T22:09:14+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 301,
                  'last_accon_time': '2023-10-25T21:19:31Z'
                },
                'latitude': -37.56231,
                'longitude': 143.86505,
                'timestamp': 1698271754
              }
            },
            'event_id': 119814,
            'event_subtype': 0,
            'event_type': 1003,
            'link': '/device/213LUL2020000004?event_id=119814',
            'source': 'system',
            'start_time': 1698271754
          },
          {
            'device_id': 15,
            'end_time': 1698272301,
            'event_data': {
              'alarm': {
                'alarm_description': '0200',
                'alarm_threshold': 0,
                'alarm_type': 22,
                'new_alarm_flag': 1
              },
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 110,
                  'odometer_reading': 516621,
                  'speed': 0,
                  'speed_cm_sec': 0
                },
                'datetime': '2023-10-25T22:18:21+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 0,
                  'last_accon_time': '2023-10-25T22:18:21Z'
                },
                'latitude': -37.56231,
                'longitude': 143.86505,
                'timestamp': 1698272301
              }
            },
            'event_id': 119815,
            'event_subtype': 0,
            'event_type': 1002,
            'link': '/device/213LUL2020000004?event_id=119815',
            'source': 'system',
            'start_time': 1698272301
          },
          {
            'device_id': 15,
            'end_time': 1698272301,
            'event_data': {
              'alarm': {
                'alarm_description': '0200',
                'alarm_threshold': 0,
                'alarm_type': 22,
                'new_alarm_flag': 1
              },
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 110,
                  'odometer_reading': 516652,
                  'speed': 0,
                  'speed_cm_sec': 0
                },
                'datetime': '2023-10-25T22:18:21+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 0,
                  'last_accon_time': '2023-10-25T22:18:21Z'
                },
                'latitude': -37.56231,
                'longitude': 143.86505,
                'timestamp': 1698272301
              }
            },
            'event_id': 119816,
            'event_subtype': 0,
            'event_type': 1002,
            'link': '/device/213LUL2020000004?event_id=119816',
            'source': 'system',
            'start_time': 1698272301
          },
          {
            'device_id': 15,
            'end_time': 1698272301,
            'event_data': {
              'alarm': {
                'alarm_description': '0200',
                'alarm_threshold': 0,
                'alarm_type': 22,
                'new_alarm_flag': 1
              },
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 110,
                  'odometer_reading': 516652,
                  'speed': 0,
                  'speed_cm_sec': 0
                },
                'datetime': '2023-10-25T22:18:21+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 0,
                  'last_accon_time': '2023-10-25T22:18:21Z'
                },
                'latitude': -37.56231,
                'longitude': 143.86505,
                'timestamp': 1698272301
              }
            },
            'event_id': 119817,
            'event_subtype': 0,
            'event_type': 1002,
            'link': '/device/213LUL2020000004?event_id=119817',
            'source': 'system',
            'start_time': 1698272301
          }
        ],
        'fuel_consumed': 308,
        'id': 79198,
        'ignition_on': null,
        'kms_travelled': 32.9936,
        'maximum_speed': 95,
        'metadata': {
          'fuel_history': {
            '2023-10-25T21:17:21Z': 0,
            '2023-10-25T21:19:31Z': 301,
            '2023-10-25T22:18:21Z': 7
          },
          'location_lookup_end': true,
          'location_lookup_start': true,
          'odometer_reading_end': 516652.429,
          'odometer_reading_start': 516616
        },
        'modified': false,
        'n_locations': 717,
        'start_latitude': -37.56863,
        'start_location_address': {
          'created': '2023-09-12T02:59:31',
          'data': {
            'address_text': '217 York St, Ballarat East VIC 3350, Australia',
            'geocode_result': {
              'address_components': [
                {
                  'long_name': '217',
                  'short_name': '217',
                  'types': [
                    'street_number'
                  ]
                },
                {
                  'long_name': 'York Street',
                  'short_name': 'York St',
                  'types': [
                    'route'
                  ]
                },
                {
                  'long_name': 'Ballarat East',
                  'short_name': 'Ballarat East',
                  'types': [
                    'locality',
                    'political'
                  ]
                },
                {
                  'long_name': 'City of Ballarat',
                  'short_name': 'City of Ballarat',
                  'types': [
                    'administrative_area_level_2',
                    'political'
                  ]
                },
                {
                  'long_name': 'Victoria',
                  'short_name': 'VIC',
                  'types': [
                    'administrative_area_level_1',
                    'political'
                  ]
                },
                {
                  'long_name': 'Australia',
                  'short_name': 'AU',
                  'types': [
                    'country',
                    'political'
                  ]
                },
                {
                  'long_name': '3350',
                  'short_name': '3350',
                  'types': [
                    'postal_code'
                  ]
                }
              ],
              'formatted_address': '217 York St, Ballarat East VIC 3350, Australia',
              'geometry': {
                'location': {
                  'lat': -37.5687487,
                  'lng': 143.8737933
                },
                'location_type': 'ROOFTOP',
                'viewport': {
                  'northeast': {
                    'lat': -37.5673997197085,
                    'lng': 143.8751422802915
                  },
                  'southwest': {
                    'lat': -37.5700976802915,
                    'lng': 143.8724443197085
                  }
                }
              },
              'place_id': 'ChIJPwra3gZF0WoR9qWR5WiaO9E',
              'plus_code': {
                'compound_code': 'CVJF+GG Ballarat East VIC, Australia',
                'global_code': '4RJ5CVJF+GG'
              },
              'types': [
                'street_address'
              ]
            }
          },
          'id': 1060,
          'location': {
            'lat': -37.5687487,
            'lng': 143.8737933
          },
          'location_type': 'address'
        },
        'start_longitude': 143.8738,
        'start_time': 1698268641,
        'state': 'MOVING',
        'tags': {},
        'time_taken': 3870,
        'trip_data': [],
        'work_use': true
      },
      testTripParked: {
        'annotation': null,
        'device_imei': '213LUL2020000004',
        'end_latitude': -37.5629,
        'end_location_address': {
          'created': '2023-09-07T06:53:04',
          'data': {
            'address_text': '219 York St, Ballarat East VIC 3350, Australia',
            'geocode_result': {
              'address_components': [
                {
                  'long_name': '219',
                  'short_name': '219',
                  'types': [
                    'street_number'
                  ]
                },
                {
                  'long_name': 'York Street',
                  'short_name': 'York St',
                  'types': [
                    'route'
                  ]
                },
                {
                  'long_name': 'Ballarat East',
                  'short_name': 'Ballarat East',
                  'types': [
                    'locality',
                    'political'
                  ]
                },
                {
                  'long_name': 'City of Ballarat',
                  'short_name': 'City of Ballarat',
                  'types': [
                    'administrative_area_level_2',
                    'political'
                  ]
                },
                {
                  'long_name': 'Victoria',
                  'short_name': 'VIC',
                  'types': [
                    'administrative_area_level_1',
                    'political'
                  ]
                },
                {
                  'long_name': 'Australia',
                  'short_name': 'AU',
                  'types': [
                    'country',
                    'political'
                  ]
                },
                {
                  'long_name': '3350',
                  'short_name': '3350',
                  'types': [
                    'postal_code'
                  ]
                }
              ],
              'formatted_address': '219 York St, Ballarat East VIC 3350, Australia',
              'geometry': {
                'location': {
                  'lat': -37.5686553,
                  'lng': 143.8741476
                },
                'location_type': 'ROOFTOP',
                'viewport': {
                  'northeast': {
                    'lat': -37.5673063197085,
                    'lng': 143.8754965802915
                  },
                  'southwest': {
                    'lat': -37.5700042802915,
                    'lng': 143.8727986197085
                  }
                }
              },
              'place_id': 'ChIJEdDlHwdF0WoRfJBVKw3SIKk',
              'plus_code': {
                'compound_code': 'CVJF+GM Ballarat East VIC, Australia',
                'global_code': '4RJ5CVJF+GM'
              },
              'types': [
                'street_address'
              ]
            }
          },
          'id': 892,
          'location': {
            'lat': -37.5686553,
            'lng': 143.8741476
          },
          'location_type': 'address'
        },
        'end_longitude': 143.86644,
        'end_time': 1698276212,
        'events': [
          {
            'device_id': 15,
            'end_time': 1698215443,
            'event_data': {
              'alarm': {
                'alarm_description': '0300',
                'alarm_threshold': 0,
                'alarm_type': 23,
                'new_alarm_flag': 1
              },
              'location': {
                'data': {
                  'acc_on': false,
                  'direction': 2930,
                  'odometer_reading': 516616,
                  'speed': 0,
                  'speed_cm_sec': 0
                },
                'datetime': '2023-10-25T06:30:43+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 69,
                  'last_accon_time': '2023-10-25T06:16:00Z'
                },
                'latitude': -37.56879,
                'longitude': 143.87402,
                'timestamp': 1698215443
              }
            },
            'event_id': 119797,
            'event_subtype': 0,
            'event_type': 1003,
            'link': '/device/213LUL2020000004?event_id=119797',
            'source': 'system',
            'start_time': 1698215443
          },
          {
            'device_id': 15,
            'end_time': 1698268641,
            'event_data': {
              'alarm': {
                'alarm_description': '0200',
                'alarm_threshold': 0,
                'alarm_type': 22,
                'new_alarm_flag': 1
              },
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 1080,
                  'odometer_reading': 516616,
                  'speed': 0,
                  'speed_cm_sec': 0
                },
                'datetime': '2023-10-25T21:17:21+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 0,
                  'last_accon_time': '2023-10-25T21:17:21Z'
                },
                'latitude': -37.56863,
                'longitude': 143.8738,
                'timestamp': 1698268641
              }
            },
            'event_id': 119802,
            'event_subtype': 0,
            'event_type': 1002,
            'link': '/device/213LUL2020000004?event_id=119802',
            'source': 'system',
            'start_time': 1698268641
          },
          {
            'device_id': 15,
            'end_time': 1698268683,
            'event_data': {
              'alarm': {
                'alarm_description': '0300',
                'alarm_threshold': 0,
                'alarm_type': 23,
                'new_alarm_flag': 1
              },
              'location': {
                'data': {
                  'acc_on': false,
                  'direction': 3390,
                  'odometer_reading': 516616,
                  'speed': 0,
                  'speed_cm_sec': 0
                },
                'datetime': '2023-10-25T21:18:03+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 0,
                  'last_accon_time': '2023-10-25T21:17:21Z'
                },
                'latitude': -37.56861,
                'longitude': 143.87389,
                'timestamp': 1698268683
              }
            },
            'event_id': 119800,
            'event_subtype': 0,
            'event_type': 1003,
            'link': '/device/213LUL2020000004?event_id=119800',
            'source': 'system',
            'start_time': 1698268683
          },
          {
            'device_id': 15,
            'end_time': 1698268683,
            'event_data': {
              'alarm': {
                'alarm_description': '0300',
                'alarm_threshold': 0,
                'alarm_type': 23,
                'new_alarm_flag': 1
              },
              'location': {
                'data': {
                  'acc_on': false,
                  'direction': 3390,
                  'odometer_reading': 516616,
                  'speed': 0,
                  'speed_cm_sec': 0
                },
                'datetime': '2023-10-25T21:18:03+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 0,
                  'last_accon_time': '2023-10-25T21:17:21Z'
                },
                'latitude': -37.56861,
                'longitude': 143.87389,
                'timestamp': 1698268683
              }
            },
            'event_id': 119801,
            'event_subtype': 0,
            'event_type': 1003,
            'link': '/device/213LUL2020000004?event_id=119801',
            'source': 'system',
            'start_time': 1698268683
          },
          {
            'device_id': 15,
            'end_time': 1698268771,
            'event_data': {
              'alarm': {
                'alarm_description': '0200',
                'alarm_threshold': 0,
                'alarm_type': 22,
                'new_alarm_flag': 1
              },
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 3390,
                  'odometer_reading': 516616,
                  'speed': 0,
                  'speed_cm_sec': 0
                },
                'datetime': '2023-10-25T21:19:31+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 0,
                  'last_accon_time': '2023-10-25T21:19:31Z'
                },
                'latitude': -37.56861,
                'longitude': 143.87389,
                'timestamp': 1698268771
              }
            },
            'event_id': 119803,
            'event_subtype': 0,
            'event_type': 1002,
            'link': '/device/213LUL2020000004?event_id=119803',
            'source': 'system',
            'start_time': 1698268771
          },
          {
            'device_id': 15,
            'end_time': 1698268978,
            'event_data': {
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 1500,
                  'odometer_reading': 516620.598,
                  'overspeed_start': 1698268938,
                  'speed': 45,
                  'speed_cm_sec': 1255
                },
                'datetime': '2023-10-25T21:22:50+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 26,
                  'last_accon_time': '2023-10-25T21:19:31Z'
                },
                'latitude': -37.57821,
                'longitude': 143.87581,
                'timestamp': 1698268978
              },
              'speed': 45
            },
            'event_id': 119804,
            'event_subtype': 0,
            'event_type': 3002,
            'link': '/device/213LUL2020000004?event_id=119804',
            'source': 'system',
            'start_time': 1698268978
          },
          {
            'device_id': 15,
            'end_time': 1698269073,
            'event_data': {
              'alarm': {
                'alarm_description': '0100',
                'alarm_threshold': 1,
                'alarm_type': 6,
                'new_alarm_flag': 1
              },
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 1110,
                  'odometer_reading': 516621,
                  'speed': 0,
                  'speed_cm_sec': 0
                },
                'datetime': '2023-10-25T21:24:33+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 32,
                  'last_accon_time': '2023-10-25T21:19:31Z'
                },
                'latitude': -37.58054,
                'longitude': 143.87634,
                'timestamp': 1698269073
              }
            },
            'event_id': 119805,
            'event_subtype': 0,
            'event_type': 3007,
            'link': '/device/213LUL2020000004?event_id=119805',
            'source': 'system',
            'start_time': 1698269073
          },
          {
            'device_id': 15,
            'end_time': 1698269128,
            'event_data': {
              'alarm': {
                'alarm_description': '0100',
                'alarm_threshold': 1,
                'alarm_type': 6,
                'new_alarm_flag': 0
              },
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 1110,
                  'odometer_reading': 516621,
                  'speed': 0,
                  'speed_cm_sec': 0
                },
                'datetime': '2023-10-25T21:25:28+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 34,
                  'last_accon_time': '2023-10-25T21:19:31Z'
                },
                'latitude': -37.58054,
                'longitude': 143.87634,
                'timestamp': 1698269128
              }
            },
            'event_id': 119806,
            'event_subtype': 0,
            'event_type': 3007,
            'link': '/device/213LUL2020000004?event_id=119806',
            'source': 'system',
            'start_time': 1698269128
          },
          {
            'device_id': 15,
            'end_time': 1698269297,
            'event_data': {
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 1970,
                  'odometer_reading': 516620.598,
                  'overspeed_start': 1698269260,
                  'speed': 46,
                  'speed_cm_sec': 1305
                },
                'datetime': '2023-10-25T21:28:11+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 55,
                  'last_accon_time': '2023-10-25T21:19:31Z'
                },
                'latitude': -37.58648,
                'longitude': 143.86702,
                'timestamp': 1698269297
              },
              'speed': 46
            },
            'event_id': 119807,
            'event_subtype': 0,
            'event_type': 3002,
            'link': '/device/213LUL2020000004?event_id=119807',
            'source': 'system',
            'start_time': 1698269297
          },
          {
            'device_id': 15,
            'end_time': 1698269667,
            'event_data': {
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 1850,
                  'odometer_reading': 516620.598,
                  'overspeed_start': 1698269633,
                  'speed': 45,
                  'speed_cm_sec': 1263
                },
                'datetime': '2023-10-25T21:34:20+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 98,
                  'last_accon_time': '2023-10-25T21:19:31Z'
                },
                'latitude': -37.6051,
                'longitude': 143.84056,
                'timestamp': 1698269667
              },
              'speed': 45
            },
            'event_id': 119808,
            'event_subtype': 0,
            'event_type': 3002,
            'link': '/device/213LUL2020000004?event_id=119808',
            'source': 'system',
            'start_time': 1698269667
          },
          {
            'device_id': 15,
            'end_time': 1698270278,
            'event_data': {
              'alarm': {
                'alarm_description': '0100',
                'alarm_threshold': 1,
                'alarm_type': 6,
                'new_alarm_flag': 1
              },
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 380,
                  'odometer_reading': 516621,
                  'speed': 0,
                  'speed_cm_sec': 0
                },
                'datetime': '2023-10-25T21:44:38+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 170,
                  'last_accon_time': '2023-10-25T21:19:31Z'
                },
                'latitude': -37.6788,
                'longitude': 143.82694,
                'timestamp': 1698270278
              }
            },
            'event_id': 119809,
            'event_subtype': 0,
            'event_type': 3007,
            'link': '/device/213LUL2020000004?event_id=119809',
            'source': 'system',
            'start_time': 1698270278
          },
          {
            'device_id': 15,
            'end_time': 1698270293,
            'event_data': {
              'alarm': {
                'alarm_description': '0100',
                'alarm_threshold': 1,
                'alarm_type': 6,
                'new_alarm_flag': 0
              },
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 380,
                  'odometer_reading': 516621,
                  'speed': 0,
                  'speed_cm_sec': 0
                },
                'datetime': '2023-10-25T21:44:53+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 171,
                  'last_accon_time': '2023-10-25T21:19:31Z'
                },
                'latitude': -37.6788,
                'longitude': 143.82694,
                'timestamp': 1698270293
              }
            },
            'event_id': 119810,
            'event_subtype': 0,
            'event_type': 3007,
            'link': '/device/213LUL2020000004?event_id=119810',
            'source': 'system',
            'start_time': 1698270293
          },
          {
            'device_id': 15,
            'end_time': 1698270388,
            'event_data': {
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 50,
                  'odometer_reading': 516620.598,
                  'overspeed_start': 1698270356,
                  'speed': 55,
                  'speed_cm_sec': 1544
                },
                'datetime': '2023-10-25T21:46:25+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 180,
                  'last_accon_time': '2023-10-25T21:19:31Z'
                },
                'latitude': -37.67095,
                'longitude': 143.83191,
                'timestamp': 1698270388
              },
              'speed': 55
            },
            'event_id': 119811,
            'event_subtype': 0,
            'event_type': 3002,
            'link': '/device/213LUL2020000004?event_id=119811',
            'source': 'system',
            'start_time': 1698270388
          },
          {
            'device_id': 15,
            'end_time': 1698270751,
            'event_data': {
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 130,
                  'odometer_reading': 516620.598,
                  'overspeed_start': 1698270721,
                  'speed': 60,
                  'speed_cm_sec': 1691
                },
                'datetime': '2023-10-25T21:52:27+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 233,
                  'last_accon_time': '2023-10-25T21:19:31Z'
                },
                'latitude': -37.60954,
                'longitude': 143.83951,
                'timestamp': 1698270751
              },
              'speed': 60
            },
            'event_id': 119812,
            'event_subtype': 0,
            'event_type': 3002,
            'link': '/device/213LUL2020000004?event_id=119812',
            'source': 'system',
            'start_time': 1698270751
          },
          {
            'device_id': 15,
            'end_time': 1698271316,
            'event_data': {
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 540,
                  'odometer_reading': 516620.598,
                  'overspeed_start': 1698271286,
                  'speed': 55,
                  'speed_cm_sec': 1550
                },
                'datetime': '2023-10-25T22:01:50+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 275,
                  'last_accon_time': '2023-10-25T21:19:31Z'
                },
                'latitude': -37.56928,
                'longitude': 143.85177,
                'timestamp': 1698271316
              },
              'speed': 55
            },
            'event_id': 119813,
            'event_subtype': 0,
            'event_type': 3002,
            'link': '/device/213LUL2020000004?event_id=119813',
            'source': 'system',
            'start_time': 1698271316
          },
          {
            'device_id': 15,
            'end_time': 1698271754,
            'event_data': {
              'alarm': {
                'alarm_description': '0300',
                'alarm_threshold': 0,
                'alarm_type': 23,
                'new_alarm_flag': 1
              },
              'location': {
                'data': {
                  'acc_on': false,
                  'direction': 110,
                  'odometer_reading': 516621,
                  'speed': 0,
                  'speed_cm_sec': 0
                },
                'datetime': '2023-10-25T22:09:14+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 301,
                  'last_accon_time': '2023-10-25T21:19:31Z'
                },
                'latitude': -37.56231,
                'longitude': 143.86505,
                'timestamp': 1698271754
              }
            },
            'event_id': 119814,
            'event_subtype': 0,
            'event_type': 1003,
            'link': '/device/213LUL2020000004?event_id=119814',
            'source': 'system',
            'start_time': 1698271754
          },
          {
            'device_id': 15,
            'end_time': 1698272301,
            'event_data': {
              'alarm': {
                'alarm_description': '0200',
                'alarm_threshold': 0,
                'alarm_type': 22,
                'new_alarm_flag': 1
              },
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 110,
                  'odometer_reading': 516621,
                  'speed': 0,
                  'speed_cm_sec': 0
                },
                'datetime': '2023-10-25T22:18:21+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 0,
                  'last_accon_time': '2023-10-25T22:18:21Z'
                },
                'latitude': -37.56231,
                'longitude': 143.86505,
                'timestamp': 1698272301
              }
            },
            'event_id': 119815,
            'event_subtype': 0,
            'event_type': 1002,
            'link': '/device/213LUL2020000004?event_id=119815',
            'source': 'system',
            'start_time': 1698272301
          },
          {
            'device_id': 15,
            'end_time': 1698272301,
            'event_data': {
              'alarm': {
                'alarm_description': '0200',
                'alarm_threshold': 0,
                'alarm_type': 22,
                'new_alarm_flag': 1
              },
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 110,
                  'odometer_reading': 516652,
                  'speed': 0,
                  'speed_cm_sec': 0
                },
                'datetime': '2023-10-25T22:18:21+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 0,
                  'last_accon_time': '2023-10-25T22:18:21Z'
                },
                'latitude': -37.56231,
                'longitude': 143.86505,
                'timestamp': 1698272301
              }
            },
            'event_id': 119816,
            'event_subtype': 0,
            'event_type': 1002,
            'link': '/device/213LUL2020000004?event_id=119816',
            'source': 'system',
            'start_time': 1698272301
          },
          {
            'device_id': 15,
            'end_time': 1698272301,
            'event_data': {
              'alarm': {
                'alarm_description': '0200',
                'alarm_threshold': 0,
                'alarm_type': 22,
                'new_alarm_flag': 1
              },
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 110,
                  'odometer_reading': 516652,
                  'speed': 0,
                  'speed_cm_sec': 0
                },
                'datetime': '2023-10-25T22:18:21+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 0,
                  'last_accon_time': '2023-10-25T22:18:21Z'
                },
                'latitude': -37.56231,
                'longitude': 143.86505,
                'timestamp': 1698272301
              }
            },
            'event_id': 119817,
            'event_subtype': 0,
            'event_type': 1002,
            'link': '/device/213LUL2020000004?event_id=119817',
            'source': 'system',
            'start_time': 1698272301
          },
          {
            'device_id': 15,
            'end_time': 1698272535,
            'event_data': {
              'alarm': {
                'alarm_description': '0300',
                'alarm_threshold': 0,
                'alarm_type': 23,
                'new_alarm_flag': 1
              },
              'location': {
                'data': {
                  'acc_on': false,
                  'direction': 1930,
                  'odometer_reading': 516652,
                  'speed': 0,
                  'speed_cm_sec': 0
                },
                'datetime': '2023-10-25T22:22:15+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 7,
                  'last_accon_time': '2023-10-25T22:18:21Z'
                },
                'latitude': -37.56215,
                'longitude': 143.86688,
                'timestamp': 1698272535
              }
            },
            'event_id': 119818,
            'event_subtype': 0,
            'event_type': 1003,
            'link': '/device/213LUL2020000004?event_id=119818',
            'source': 'system',
            'start_time': 1698272535
          }
        ],
        'fuel_consumed': 308,
        'id': 79197,
        'ignition_on': null,
        'kms_travelled': 2.14848,
        'maximum_speed': 8,
        'metadata': {
          'fuel_history': {
            '2023-10-25T06:16:00Z': 0,
            '2023-10-25T21:17:21Z': 0,
            '2023-10-25T21:19:31Z': 301,
            '2023-10-25T22:18:21Z': 7
          },
          'location_lookup_end': true,
          'location_lookup_start': true,
          'odometer_reading_end': 516652,
          'odometer_reading_start': 516616
        },
        'modified': false,
        'n_locations': 20,
        'start_latitude': -37.56879,
        'start_location_address': {
          'created': '2023-09-07T06:53:04',
          'data': {
            'address_text': '219 York St, Ballarat East VIC 3350, Australia',
            'geocode_result': {
              'address_components': [
                {
                  'long_name': '219',
                  'short_name': '219',
                  'types': [
                    'street_number'
                  ]
                },
                {
                  'long_name': 'York Street',
                  'short_name': 'York St',
                  'types': [
                    'route'
                  ]
                },
                {
                  'long_name': 'Ballarat East',
                  'short_name': 'Ballarat East',
                  'types': [
                    'locality',
                    'political'
                  ]
                },
                {
                  'long_name': 'City of Ballarat',
                  'short_name': 'City of Ballarat',
                  'types': [
                    'administrative_area_level_2',
                    'political'
                  ]
                },
                {
                  'long_name': 'Victoria',
                  'short_name': 'VIC',
                  'types': [
                    'administrative_area_level_1',
                    'political'
                  ]
                },
                {
                  'long_name': 'Australia',
                  'short_name': 'AU',
                  'types': [
                    'country',
                    'political'
                  ]
                },
                {
                  'long_name': '3350',
                  'short_name': '3350',
                  'types': [
                    'postal_code'
                  ]
                }
              ],
              'formatted_address': '219 York St, Ballarat East VIC 3350, Australia',
              'geometry': {
                'location': {
                  'lat': -37.5686553,
                  'lng': 143.8741476
                },
                'location_type': 'ROOFTOP',
                'viewport': {
                  'northeast': {
                    'lat': -37.5673063197085,
                    'lng': 143.8754965802915
                  },
                  'southwest': {
                    'lat': -37.5700042802915,
                    'lng': 143.8727986197085
                  }
                }
              },
              'place_id': 'ChIJEdDlHwdF0WoRfJBVKw3SIKk',
              'plus_code': {
                'compound_code': 'CVJF+GM Ballarat East VIC, Australia',
                'global_code': '4RJ5CVJF+GM'
              },
              'types': [
                'street_address'
              ]
            }
          },
          'id': 892,
          'location': {
            'lat': -37.5686553,
            'lng': 143.8741476
          },
          'location_type': 'address'
        },
        'start_longitude': 143.87402,
        'start_time': 1698215443,
        'state': 'PARKED',
        'tags': {},
        'time_taken': 60769,
        'trip_data': [],
        'work_use': true
      },
      testTripForms: {
        'annotation': null,
        'device_imei': '213LUL2020000004',
        'end_latitude': -37.56215,
        'end_location_address': {
          'created': '2023-08-17T05:02:52',
          'data': {
            'address_text': '53 Victoria St, Bakery Hill VIC 3350, Australia',
            'geocode_result': {
              'address_components': [
                {
                  'long_name': '53',
                  'short_name': '53',
                  'types': [
                    'street_number'
                  ]
                },
                {
                  'long_name': 'Victoria Street',
                  'short_name': 'Victoria St',
                  'types': [
                    'route'
                  ]
                },
                {
                  'long_name': 'Bakery Hill',
                  'short_name': 'Bakery Hill',
                  'types': [
                    'locality',
                    'political'
                  ]
                },
                {
                  'long_name': 'City of Ballarat',
                  'short_name': 'City of Ballarat',
                  'types': [
                    'administrative_area_level_2',
                    'political'
                  ]
                },
                {
                  'long_name': 'Victoria',
                  'short_name': 'VIC',
                  'types': [
                    'administrative_area_level_1',
                    'political'
                  ]
                },
                {
                  'long_name': 'Australia',
                  'short_name': 'AU',
                  'types': [
                    'country',
                    'political'
                  ]
                },
                {
                  'long_name': '3350',
                  'short_name': '3350',
                  'types': [
                    'postal_code'
                  ]
                }
              ],
              'formatted_address': '53 Victoria St, Bakery Hill VIC 3350, Australia',
              'geometry': {
                'bounds': {
                  'northeast': {
                    'lat': -37.5621042,
                    'lng': 143.8670506
                  },
                  'southwest': {
                    'lat': -37.5623086,
                    'lng': 143.8668352
                  }
                },
                'location': {
                  'lat': -37.5622057,
                  'lng': 143.8669533
                },
                'location_type': 'ROOFTOP',
                'viewport': {
                  'northeast': {
                    'lat': -37.5608574197085,
                    'lng': 143.8682918802915
                  },
                  'southwest': {
                    'lat': -37.5635553802915,
                    'lng': 143.8655939197085
                  }
                }
              },
              'place_id': 'ChIJBw1GNFVE0WoRHgfwoZtd3Ms',
              'types': [
                'premise'
              ]
            }
          },
          'id': 11,
          'location': {
            'lat': -37.5622057,
            'lng': 143.8669533
          },
          'location_type': 'address'
        },
        'end_longitude': 143.86688,
        'end_time': 1698272511,
        'events': [
          {
            'device_id': 15,
            'end_time': 1698268641,
            'event_data': {
              'alarm': {
                'alarm_description': '0200',
                'alarm_threshold': 0,
                'alarm_type': 22,
                'new_alarm_flag': 1
              },
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 1080,
                  'odometer_reading': 516616,
                  'speed': 0,
                  'speed_cm_sec': 0
                },
                'datetime': '2023-10-25T21:17:21+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 0,
                  'last_accon_time': '2023-10-25T21:17:21Z'
                },
                'latitude': -37.56863,
                'longitude': 143.8738,
                'timestamp': 1698268641
              }
            },
            'event_id': 119802,
            'event_subtype': 0,
            'event_type': 1002,
            'link': '/device/213LUL2020000004?event_id=119802',
            'source': 'system',
            'start_time': 1698268641
          },
          {
            'device_id': 15,
            'end_time': 1698268683,
            'event_data': {
              'alarm': {
                'alarm_description': '0300',
                'alarm_threshold': 0,
                'alarm_type': 23,
                'new_alarm_flag': 1
              },
              'location': {
                'data': {
                  'acc_on': false,
                  'direction': 3390,
                  'odometer_reading': 516616,
                  'speed': 0,
                  'speed_cm_sec': 0
                },
                'datetime': '2023-10-25T21:18:03+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 0,
                  'last_accon_time': '2023-10-25T21:17:21Z'
                },
                'latitude': -37.56861,
                'longitude': 143.87389,
                'timestamp': 1698268683
              }
            },
            'event_id': 119800,
            'event_subtype': 0,
            'event_type': 1003,
            'link': '/device/213LUL2020000004?event_id=119800',
            'source': 'system',
            'start_time': 1698268683
          },
          {
            'device_id': 15,
            'end_time': 1698268683,
            'event_data': {
              'alarm': {
                'alarm_description': '0300',
                'alarm_threshold': 0,
                'alarm_type': 23,
                'new_alarm_flag': 1
              },
              'location': {
                'data': {
                  'acc_on': false,
                  'direction': 3390,
                  'odometer_reading': 516616,
                  'speed': 0,
                  'speed_cm_sec': 0
                },
                'datetime': '2023-10-25T21:18:03+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 0,
                  'last_accon_time': '2023-10-25T21:17:21Z'
                },
                'latitude': -37.56861,
                'longitude': 143.87389,
                'timestamp': 1698268683
              }
            },
            'event_id': 119801,
            'event_subtype': 0,
            'event_type': 1003,
            'link': '/device/213LUL2020000004?event_id=119801',
            'source': 'system',
            'start_time': 1698268683
          },
          {
            'device_id': 15,
            'end_time': 1698268771,
            'event_data': {
              'alarm': {
                'alarm_description': '0200',
                'alarm_threshold': 0,
                'alarm_type': 22,
                'new_alarm_flag': 1
              },
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 3390,
                  'odometer_reading': 516616,
                  'speed': 0,
                  'speed_cm_sec': 0
                },
                'datetime': '2023-10-25T21:19:31+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 0,
                  'last_accon_time': '2023-10-25T21:19:31Z'
                },
                'latitude': -37.56861,
                'longitude': 143.87389,
                'timestamp': 1698268771
              }
            },
            'event_id': 119803,
            'event_subtype': 0,
            'event_type': 1002,
            'link': '/device/213LUL2020000004?event_id=119803',
            'source': 'system',
            'start_time': 1698268771
          },
          {
            'device_id': 15,
            'end_time': 1698268978,
            'event_data': {
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 1500,
                  'odometer_reading': 516620.598,
                  'overspeed_start': 1698268938,
                  'speed': 45,
                  'speed_cm_sec': 1255
                },
                'datetime': '2023-10-25T21:22:50+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 26,
                  'last_accon_time': '2023-10-25T21:19:31Z'
                },
                'latitude': -37.57821,
                'longitude': 143.87581,
                'timestamp': 1698268978
              },
              'speed': 45
            },
            'event_id': 119804,
            'event_subtype': 0,
            'event_type': 3002,
            'link': '/device/213LUL2020000004?event_id=119804',
            'source': 'system',
            'start_time': 1698268978
          },
          {
            'device_id': 15,
            'end_time': 1698269073,
            'event_data': {
              'alarm': {
                'alarm_description': '0100',
                'alarm_threshold': 1,
                'alarm_type': 6,
                'new_alarm_flag': 1
              },
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 1110,
                  'odometer_reading': 516621,
                  'speed': 0,
                  'speed_cm_sec': 0
                },
                'datetime': '2023-10-25T21:24:33+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 32,
                  'last_accon_time': '2023-10-25T21:19:31Z'
                },
                'latitude': -37.58054,
                'longitude': 143.87634,
                'timestamp': 1698269073
              }
            },
            'event_id': 119805,
            'event_subtype': 0,
            'event_type': 3007,
            'link': '/device/213LUL2020000004?event_id=119805',
            'source': 'system',
            'start_time': 1698269073
          },
          {
            'device_id': 15,
            'end_time': 1698269128,
            'event_data': {
              'alarm': {
                'alarm_description': '0100',
                'alarm_threshold': 1,
                'alarm_type': 6,
                'new_alarm_flag': 0
              },
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 1110,
                  'odometer_reading': 516621,
                  'speed': 0,
                  'speed_cm_sec': 0
                },
                'datetime': '2023-10-25T21:25:28+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 34,
                  'last_accon_time': '2023-10-25T21:19:31Z'
                },
                'latitude': -37.58054,
                'longitude': 143.87634,
                'timestamp': 1698269128
              }
            },
            'event_id': 119806,
            'event_subtype': 0,
            'event_type': 3007,
            'link': '/device/213LUL2020000004?event_id=119806',
            'source': 'system',
            'start_time': 1698269128
          },
          {
            'device_id': 15,
            'end_time': 1698269297,
            'event_data': {
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 1970,
                  'odometer_reading': 516620.598,
                  'overspeed_start': 1698269260,
                  'speed': 46,
                  'speed_cm_sec': 1305
                },
                'datetime': '2023-10-25T21:28:11+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 55,
                  'last_accon_time': '2023-10-25T21:19:31Z'
                },
                'latitude': -37.58648,
                'longitude': 143.86702,
                'timestamp': 1698269297
              },
              'speed': 46
            },
            'event_id': 119807,
            'event_subtype': 0,
            'event_type': 3002,
            'link': '/device/213LUL2020000004?event_id=119807',
            'source': 'system',
            'start_time': 1698269297
          },
          {
            'device_id': 15,
            'end_time': 1698269667,
            'event_data': {
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 1850,
                  'odometer_reading': 516620.598,
                  'overspeed_start': 1698269633,
                  'speed': 45,
                  'speed_cm_sec': 1263
                },
                'datetime': '2023-10-25T21:34:20+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 98,
                  'last_accon_time': '2023-10-25T21:19:31Z'
                },
                'latitude': -37.6051,
                'longitude': 143.84056,
                'timestamp': 1698269667
              },
              'speed': 45
            },
            'event_id': 119808,
            'event_subtype': 0,
            'event_type': 3002,
            'link': '/device/213LUL2020000004?event_id=119808',
            'source': 'system',
            'start_time': 1698269667
          },
          {
            'device_id': 15,
            'end_time': 1698270278,
            'event_data': {
              'alarm': {
                'alarm_description': '0100',
                'alarm_threshold': 1,
                'alarm_type': 6,
                'new_alarm_flag': 1
              },
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 380,
                  'odometer_reading': 516621,
                  'speed': 0,
                  'speed_cm_sec': 0
                },
                'datetime': '2023-10-25T21:44:38+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 170,
                  'last_accon_time': '2023-10-25T21:19:31Z'
                },
                'latitude': -37.6788,
                'longitude': 143.82694,
                'timestamp': 1698270278
              }
            },
            'event_id': 119809,
            'event_subtype': 0,
            'event_type': 3007,
            'link': '/device/213LUL2020000004?event_id=119809',
            'source': 'system',
            'start_time': 1698270278
          },
          {
            'device_id': 15,
            'end_time': 1698270293,
            'event_data': {
              'alarm': {
                'alarm_description': '0100',
                'alarm_threshold': 1,
                'alarm_type': 6,
                'new_alarm_flag': 0
              },
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 380,
                  'odometer_reading': 516621,
                  'speed': 0,
                  'speed_cm_sec': 0
                },
                'datetime': '2023-10-25T21:44:53+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 171,
                  'last_accon_time': '2023-10-25T21:19:31Z'
                },
                'latitude': -37.6788,
                'longitude': 143.82694,
                'timestamp': 1698270293
              }
            },
            'event_id': 119810,
            'event_subtype': 0,
            'event_type': 3007,
            'link': '/device/213LUL2020000004?event_id=119810',
            'source': 'system',
            'start_time': 1698270293
          },
          {
            'device_id': 15,
            'end_time': 1698270388,
            'event_data': {
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 50,
                  'odometer_reading': 516620.598,
                  'overspeed_start': 1698270356,
                  'speed': 55,
                  'speed_cm_sec': 1544
                },
                'datetime': '2023-10-25T21:46:25+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 180,
                  'last_accon_time': '2023-10-25T21:19:31Z'
                },
                'latitude': -37.67095,
                'longitude': 143.83191,
                'timestamp': 1698270388
              },
              'speed': 55
            },
            'event_id': 119811,
            'event_subtype': 0,
            'event_type': 3002,
            'link': '/device/213LUL2020000004?event_id=119811',
            'source': 'system',
            'start_time': 1698270388
          },
          {
            'device_id': 15,
            'end_time': 1698270751,
            'event_data': {
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 130,
                  'odometer_reading': 516620.598,
                  'overspeed_start': 1698270721,
                  'speed': 60,
                  'speed_cm_sec': 1691
                },
                'datetime': '2023-10-25T21:52:27+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 233,
                  'last_accon_time': '2023-10-25T21:19:31Z'
                },
                'latitude': -37.60954,
                'longitude': 143.83951,
                'timestamp': 1698270751
              },
              'speed': 60
            },
            'event_id': 119812,
            'event_subtype': 0,
            'event_type': 3002,
            'link': '/device/213LUL2020000004?event_id=119812',
            'source': 'system',
            'start_time': 1698270751
          },
          {
            'device_id': 15,
            'end_time': 1698271316,
            'event_data': {
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 540,
                  'odometer_reading': 516620.598,
                  'overspeed_start': 1698271286,
                  'speed': 55,
                  'speed_cm_sec': 1550
                },
                'datetime': '2023-10-25T22:01:50+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 275,
                  'last_accon_time': '2023-10-25T21:19:31Z'
                },
                'latitude': -37.56928,
                'longitude': 143.85177,
                'timestamp': 1698271316
              },
              'speed': 55
            },
            'event_id': 119813,
            'event_subtype': 0,
            'event_type': 3002,
            'link': '/device/213LUL2020000004?event_id=119813',
            'source': 'system',
            'start_time': 1698271316
          },
          {
            'device_id': 15,
            'end_time': 1698271754,
            'event_data': {
              'alarm': {
                'alarm_description': '0300',
                'alarm_threshold': 0,
                'alarm_type': 23,
                'new_alarm_flag': 1
              },
              'location': {
                'data': {
                  'acc_on': false,
                  'direction': 110,
                  'odometer_reading': 516621,
                  'speed': 0,
                  'speed_cm_sec': 0
                },
                'datetime': '2023-10-25T22:09:14+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 301,
                  'last_accon_time': '2023-10-25T21:19:31Z'
                },
                'latitude': -37.56231,
                'longitude': 143.86505,
                'timestamp': 1698271754
              }
            },
            'event_id': 119814,
            'event_subtype': 0,
            'event_type': 1003,
            'link': '/device/213LUL2020000004?event_id=119814',
            'source': 'system',
            'start_time': 1698271754
          },
          {
            'device_id': 15,
            'end_time': 1698272301,
            'event_data': {
              'alarm': {
                'alarm_description': '0200',
                'alarm_threshold': 0,
                'alarm_type': 22,
                'new_alarm_flag': 1
              },
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 110,
                  'odometer_reading': 516621,
                  'speed': 0,
                  'speed_cm_sec': 0
                },
                'datetime': '2023-10-25T22:18:21+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 0,
                  'last_accon_time': '2023-10-25T22:18:21Z'
                },
                'latitude': -37.56231,
                'longitude': 143.86505,
                'timestamp': 1698272301
              }
            },
            'event_id': 119815,
            'event_subtype': 0,
            'event_type': 1002,
            'link': '/device/213LUL2020000004?event_id=119815',
            'source': 'system',
            'start_time': 1698272301
          },
          {
            'device_id': 15,
            'end_time': 1698272301,
            'event_data': {
              'alarm': {
                'alarm_description': '0200',
                'alarm_threshold': 0,
                'alarm_type': 22,
                'new_alarm_flag': 1
              },
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 110,
                  'odometer_reading': 516652,
                  'speed': 0,
                  'speed_cm_sec': 0
                },
                'datetime': '2023-10-25T22:18:21+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 0,
                  'last_accon_time': '2023-10-25T22:18:21Z'
                },
                'latitude': -37.56231,
                'longitude': 143.86505,
                'timestamp': 1698272301
              }
            },
            'event_id': 119816,
            'event_subtype': 0,
            'event_type': 1002,
            'link': '/device/213LUL2020000004?event_id=119816',
            'source': 'system',
            'start_time': 1698272301
          },
          {
            'device_id': 15,
            'end_time': 1698272301,
            'event_data': {
              'alarm': {
                'alarm_description': '0200',
                'alarm_threshold': 0,
                'alarm_type': 22,
                'new_alarm_flag': 1
              },
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 110,
                  'odometer_reading': 516652,
                  'speed': 0,
                  'speed_cm_sec': 0
                },
                'datetime': '2023-10-25T22:18:21+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 0,
                  'last_accon_time': '2023-10-25T22:18:21Z'
                },
                'latitude': -37.56231,
                'longitude': 143.86505,
                'timestamp': 1698272301
              }
            },
            'event_id': 119817,
            'event_subtype': 0,
            'event_type': 1002,
            'link': '/device/213LUL2020000004?event_id=119817',
            'source': 'system',
            'start_time': 1698272301
          }
        ],
        'fuel_consumed': 308,
        'id': 79198,
        'ignition_on': null,
        'kms_travelled': 32.9936,
        'maximum_speed': 95,
        'metadata': {
          'fuel_history': {
            '2023-10-25T21:17:21Z': 0,
            '2023-10-25T21:19:31Z': 301,
            '2023-10-25T22:18:21Z': 7
          },
          'location_lookup_end': true,
          'location_lookup_start': true,
          'odometer_reading_end': 516652.429,
          'odometer_reading_start': 516616
        },
        'modified': false,
        'n_locations': 717,
        'start_latitude': -37.56863,
        'start_location_address': {
          'created': '2023-09-12T02:59:31',
          'data': {
            'address_text': '217 York St, Ballarat East VIC 3350, Australia',
            'geocode_result': {
              'address_components': [
                {
                  'long_name': '217',
                  'short_name': '217',
                  'types': [
                    'street_number'
                  ]
                },
                {
                  'long_name': 'York Street',
                  'short_name': 'York St',
                  'types': [
                    'route'
                  ]
                },
                {
                  'long_name': 'Ballarat East',
                  'short_name': 'Ballarat East',
                  'types': [
                    'locality',
                    'political'
                  ]
                },
                {
                  'long_name': 'City of Ballarat',
                  'short_name': 'City of Ballarat',
                  'types': [
                    'administrative_area_level_2',
                    'political'
                  ]
                },
                {
                  'long_name': 'Victoria',
                  'short_name': 'VIC',
                  'types': [
                    'administrative_area_level_1',
                    'political'
                  ]
                },
                {
                  'long_name': 'Australia',
                  'short_name': 'AU',
                  'types': [
                    'country',
                    'political'
                  ]
                },
                {
                  'long_name': '3350',
                  'short_name': '3350',
                  'types': [
                    'postal_code'
                  ]
                }
              ],
              'formatted_address': '217 York St, Ballarat East VIC 3350, Australia',
              'geometry': {
                'location': {
                  'lat': -37.5687487,
                  'lng': 143.8737933
                },
                'location_type': 'ROOFTOP',
                'viewport': {
                  'northeast': {
                    'lat': -37.5673997197085,
                    'lng': 143.8751422802915
                  },
                  'southwest': {
                    'lat': -37.5700976802915,
                    'lng': 143.8724443197085
                  }
                }
              },
              'place_id': 'ChIJPwra3gZF0WoR9qWR5WiaO9E',
              'plus_code': {
                'compound_code': 'CVJF+GG Ballarat East VIC, Australia',
                'global_code': '4RJ5CVJF+GG'
              },
              'types': [
                'street_address'
              ]
            }
          },
          'id': 1060,
          'location': {
            'lat': -37.5687487,
            'lng': 143.8737933
          },
          'location_type': 'address'
        },
        'start_longitude': 143.8738,
        'start_time': 1698268641,
        'state': 'MOVING',
        'tags': {},
        'time_taken': 3870,
        'trip_data': [],
        'work_use': true,
        'forms': [
          {
            'created': '2023-10-03T21:59:53',
            'data': {
              'checkedCoolantLevel': true,
              'checkedLeaks': true,
              'checkedLights': true,
              'checkedOilLevel': true,
              'checkedSeatbelts': true,
              'checkedWiperLevel': true,
              'hasEngineAlarm': false,
              'hasOtherProblem': false,
              'hasPanelDamage': false
            },
            'id': 55,
            'owner': {
              'email': 'robert@pro-tekt.com.au',
              'name': 'Robert Layton1',
              'user_id': 1
            },
            'owner_id': 1,
            'related_id': 15,
            'related_type': 'device',
            'state': 'review_required',
            'template': {
              'color': '#d36327',
              'created': '2023-08-17T00:55:06',
              'description': null,
              'icon': 'fa-car',
              'is_public': true,
              'name': 'Prestart Rules Test',
              'owner_id': null,
              'revision_number': 31,
              'schema': {},
              'template_id': 10,
              'valid_related_types': null
            },
            'updated': '2023-10-03T21:59:53'
          },
        ]
      },
      testTripNotes: {
        'annotation': 'I went to the bathroom twice on this trip',
        'device_imei': '213LUL2020000004',
        'end_latitude': -37.56215,
        'end_location_address': {
          'created': '2023-08-17T05:02:52',
          'data': {
            'address_text': '53 Victoria St, Bakery Hill VIC 3350, Australia',
            'geocode_result': {
              'address_components': [
                {
                  'long_name': '53',
                  'short_name': '53',
                  'types': [
                    'street_number'
                  ]
                },
                {
                  'long_name': 'Victoria Street',
                  'short_name': 'Victoria St',
                  'types': [
                    'route'
                  ]
                },
                {
                  'long_name': 'Bakery Hill',
                  'short_name': 'Bakery Hill',
                  'types': [
                    'locality',
                    'political'
                  ]
                },
                {
                  'long_name': 'City of Ballarat',
                  'short_name': 'City of Ballarat',
                  'types': [
                    'administrative_area_level_2',
                    'political'
                  ]
                },
                {
                  'long_name': 'Victoria',
                  'short_name': 'VIC',
                  'types': [
                    'administrative_area_level_1',
                    'political'
                  ]
                },
                {
                  'long_name': 'Australia',
                  'short_name': 'AU',
                  'types': [
                    'country',
                    'political'
                  ]
                },
                {
                  'long_name': '3350',
                  'short_name': '3350',
                  'types': [
                    'postal_code'
                  ]
                }
              ],
              'formatted_address': '53 Victoria St, Bakery Hill VIC 3350, Australia',
              'geometry': {
                'bounds': {
                  'northeast': {
                    'lat': -37.5621042,
                    'lng': 143.8670506
                  },
                  'southwest': {
                    'lat': -37.5623086,
                    'lng': 143.8668352
                  }
                },
                'location': {
                  'lat': -37.5622057,
                  'lng': 143.8669533
                },
                'location_type': 'ROOFTOP',
                'viewport': {
                  'northeast': {
                    'lat': -37.5608574197085,
                    'lng': 143.8682918802915
                  },
                  'southwest': {
                    'lat': -37.5635553802915,
                    'lng': 143.8655939197085
                  }
                }
              },
              'place_id': 'ChIJBw1GNFVE0WoRHgfwoZtd3Ms',
              'types': [
                'premise'
              ]
            }
          },
          'id': 11,
          'location': {
            'lat': -37.5622057,
            'lng': 143.8669533
          },
          'location_type': 'address'
        },
        'end_longitude': 143.86688,
        'end_time': 1698272511,
        'events': [
          {
            'device_id': 15,
            'end_time': 1698268641,
            'event_data': {
              'alarm': {
                'alarm_description': '0200',
                'alarm_threshold': 0,
                'alarm_type': 22,
                'new_alarm_flag': 1
              },
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 1080,
                  'odometer_reading': 516616,
                  'speed': 0,
                  'speed_cm_sec': 0
                },
                'datetime': '2023-10-25T21:17:21+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 0,
                  'last_accon_time': '2023-10-25T21:17:21Z'
                },
                'latitude': -37.56863,
                'longitude': 143.8738,
                'timestamp': 1698268641
              }
            },
            'event_id': 119802,
            'event_subtype': 0,
            'event_type': 1002,
            'link': '/device/213LUL2020000004?event_id=119802',
            'source': 'system',
            'start_time': 1698268641
          },
          {
            'device_id': 15,
            'end_time': 1698268683,
            'event_data': {
              'alarm': {
                'alarm_description': '0300',
                'alarm_threshold': 0,
                'alarm_type': 23,
                'new_alarm_flag': 1
              },
              'location': {
                'data': {
                  'acc_on': false,
                  'direction': 3390,
                  'odometer_reading': 516616,
                  'speed': 0,
                  'speed_cm_sec': 0
                },
                'datetime': '2023-10-25T21:18:03+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 0,
                  'last_accon_time': '2023-10-25T21:17:21Z'
                },
                'latitude': -37.56861,
                'longitude': 143.87389,
                'timestamp': 1698268683
              }
            },
            'event_id': 119800,
            'event_subtype': 0,
            'event_type': 1003,
            'link': '/device/213LUL2020000004?event_id=119800',
            'source': 'system',
            'start_time': 1698268683
          },
          {
            'device_id': 15,
            'end_time': 1698268683,
            'event_data': {
              'alarm': {
                'alarm_description': '0300',
                'alarm_threshold': 0,
                'alarm_type': 23,
                'new_alarm_flag': 1
              },
              'location': {
                'data': {
                  'acc_on': false,
                  'direction': 3390,
                  'odometer_reading': 516616,
                  'speed': 0,
                  'speed_cm_sec': 0
                },
                'datetime': '2023-10-25T21:18:03+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 0,
                  'last_accon_time': '2023-10-25T21:17:21Z'
                },
                'latitude': -37.56861,
                'longitude': 143.87389,
                'timestamp': 1698268683
              }
            },
            'event_id': 119801,
            'event_subtype': 0,
            'event_type': 1003,
            'link': '/device/213LUL2020000004?event_id=119801',
            'source': 'system',
            'start_time': 1698268683
          },
          {
            'device_id': 15,
            'end_time': 1698268771,
            'event_data': {
              'alarm': {
                'alarm_description': '0200',
                'alarm_threshold': 0,
                'alarm_type': 22,
                'new_alarm_flag': 1
              },
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 3390,
                  'odometer_reading': 516616,
                  'speed': 0,
                  'speed_cm_sec': 0
                },
                'datetime': '2023-10-25T21:19:31+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 0,
                  'last_accon_time': '2023-10-25T21:19:31Z'
                },
                'latitude': -37.56861,
                'longitude': 143.87389,
                'timestamp': 1698268771
              }
            },
            'event_id': 119803,
            'event_subtype': 0,
            'event_type': 1002,
            'link': '/device/213LUL2020000004?event_id=119803',
            'source': 'system',
            'start_time': 1698268771
          },
          {
            'device_id': 15,
            'end_time': 1698268978,
            'event_data': {
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 1500,
                  'odometer_reading': 516620.598,
                  'overspeed_start': 1698268938,
                  'speed': 45,
                  'speed_cm_sec': 1255
                },
                'datetime': '2023-10-25T21:22:50+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 26,
                  'last_accon_time': '2023-10-25T21:19:31Z'
                },
                'latitude': -37.57821,
                'longitude': 143.87581,
                'timestamp': 1698268978
              },
              'speed': 45
            },
            'event_id': 119804,
            'event_subtype': 0,
            'event_type': 3002,
            'link': '/device/213LUL2020000004?event_id=119804',
            'source': 'system',
            'start_time': 1698268978
          },
          {
            'device_id': 15,
            'end_time': 1698269073,
            'event_data': {
              'alarm': {
                'alarm_description': '0100',
                'alarm_threshold': 1,
                'alarm_type': 6,
                'new_alarm_flag': 1
              },
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 1110,
                  'odometer_reading': 516621,
                  'speed': 0,
                  'speed_cm_sec': 0
                },
                'datetime': '2023-10-25T21:24:33+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 32,
                  'last_accon_time': '2023-10-25T21:19:31Z'
                },
                'latitude': -37.58054,
                'longitude': 143.87634,
                'timestamp': 1698269073
              }
            },
            'event_id': 119805,
            'event_subtype': 0,
            'event_type': 3007,
            'link': '/device/213LUL2020000004?event_id=119805',
            'source': 'system',
            'start_time': 1698269073
          },
          {
            'device_id': 15,
            'end_time': 1698269128,
            'event_data': {
              'alarm': {
                'alarm_description': '0100',
                'alarm_threshold': 1,
                'alarm_type': 6,
                'new_alarm_flag': 0
              },
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 1110,
                  'odometer_reading': 516621,
                  'speed': 0,
                  'speed_cm_sec': 0
                },
                'datetime': '2023-10-25T21:25:28+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 34,
                  'last_accon_time': '2023-10-25T21:19:31Z'
                },
                'latitude': -37.58054,
                'longitude': 143.87634,
                'timestamp': 1698269128
              }
            },
            'event_id': 119806,
            'event_subtype': 0,
            'event_type': 3007,
            'link': '/device/213LUL2020000004?event_id=119806',
            'source': 'system',
            'start_time': 1698269128
          },
          {
            'device_id': 15,
            'end_time': 1698269297,
            'event_data': {
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 1970,
                  'odometer_reading': 516620.598,
                  'overspeed_start': 1698269260,
                  'speed': 46,
                  'speed_cm_sec': 1305
                },
                'datetime': '2023-10-25T21:28:11+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 55,
                  'last_accon_time': '2023-10-25T21:19:31Z'
                },
                'latitude': -37.58648,
                'longitude': 143.86702,
                'timestamp': 1698269297
              },
              'speed': 46
            },
            'event_id': 119807,
            'event_subtype': 0,
            'event_type': 3002,
            'link': '/device/213LUL2020000004?event_id=119807',
            'source': 'system',
            'start_time': 1698269297
          },
          {
            'device_id': 15,
            'end_time': 1698269667,
            'event_data': {
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 1850,
                  'odometer_reading': 516620.598,
                  'overspeed_start': 1698269633,
                  'speed': 45,
                  'speed_cm_sec': 1263
                },
                'datetime': '2023-10-25T21:34:20+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 98,
                  'last_accon_time': '2023-10-25T21:19:31Z'
                },
                'latitude': -37.6051,
                'longitude': 143.84056,
                'timestamp': 1698269667
              },
              'speed': 45
            },
            'event_id': 119808,
            'event_subtype': 0,
            'event_type': 3002,
            'link': '/device/213LUL2020000004?event_id=119808',
            'source': 'system',
            'start_time': 1698269667
          },
          {
            'device_id': 15,
            'end_time': 1698270278,
            'event_data': {
              'alarm': {
                'alarm_description': '0100',
                'alarm_threshold': 1,
                'alarm_type': 6,
                'new_alarm_flag': 1
              },
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 380,
                  'odometer_reading': 516621,
                  'speed': 0,
                  'speed_cm_sec': 0
                },
                'datetime': '2023-10-25T21:44:38+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 170,
                  'last_accon_time': '2023-10-25T21:19:31Z'
                },
                'latitude': -37.6788,
                'longitude': 143.82694,
                'timestamp': 1698270278
              }
            },
            'event_id': 119809,
            'event_subtype': 0,
            'event_type': 3007,
            'link': '/device/213LUL2020000004?event_id=119809',
            'source': 'system',
            'start_time': 1698270278
          },
          {
            'device_id': 15,
            'end_time': 1698270293,
            'event_data': {
              'alarm': {
                'alarm_description': '0100',
                'alarm_threshold': 1,
                'alarm_type': 6,
                'new_alarm_flag': 0
              },
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 380,
                  'odometer_reading': 516621,
                  'speed': 0,
                  'speed_cm_sec': 0
                },
                'datetime': '2023-10-25T21:44:53+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 171,
                  'last_accon_time': '2023-10-25T21:19:31Z'
                },
                'latitude': -37.6788,
                'longitude': 143.82694,
                'timestamp': 1698270293
              }
            },
            'event_id': 119810,
            'event_subtype': 0,
            'event_type': 3007,
            'link': '/device/213LUL2020000004?event_id=119810',
            'source': 'system',
            'start_time': 1698270293
          },
          {
            'device_id': 15,
            'end_time': 1698270388,
            'event_data': {
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 50,
                  'odometer_reading': 516620.598,
                  'overspeed_start': 1698270356,
                  'speed': 55,
                  'speed_cm_sec': 1544
                },
                'datetime': '2023-10-25T21:46:25+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 180,
                  'last_accon_time': '2023-10-25T21:19:31Z'
                },
                'latitude': -37.67095,
                'longitude': 143.83191,
                'timestamp': 1698270388
              },
              'speed': 55
            },
            'event_id': 119811,
            'event_subtype': 0,
            'event_type': 3002,
            'link': '/device/213LUL2020000004?event_id=119811',
            'source': 'system',
            'start_time': 1698270388
          },
          {
            'device_id': 15,
            'end_time': 1698270751,
            'event_data': {
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 130,
                  'odometer_reading': 516620.598,
                  'overspeed_start': 1698270721,
                  'speed': 60,
                  'speed_cm_sec': 1691
                },
                'datetime': '2023-10-25T21:52:27+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 233,
                  'last_accon_time': '2023-10-25T21:19:31Z'
                },
                'latitude': -37.60954,
                'longitude': 143.83951,
                'timestamp': 1698270751
              },
              'speed': 60
            },
            'event_id': 119812,
            'event_subtype': 0,
            'event_type': 3002,
            'link': '/device/213LUL2020000004?event_id=119812',
            'source': 'system',
            'start_time': 1698270751
          },
          {
            'device_id': 15,
            'end_time': 1698271316,
            'event_data': {
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 540,
                  'odometer_reading': 516620.598,
                  'overspeed_start': 1698271286,
                  'speed': 55,
                  'speed_cm_sec': 1550
                },
                'datetime': '2023-10-25T22:01:50+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 275,
                  'last_accon_time': '2023-10-25T21:19:31Z'
                },
                'latitude': -37.56928,
                'longitude': 143.85177,
                'timestamp': 1698271316
              },
              'speed': 55
            },
            'event_id': 119813,
            'event_subtype': 0,
            'event_type': 3002,
            'link': '/device/213LUL2020000004?event_id=119813',
            'source': 'system',
            'start_time': 1698271316
          },
          {
            'device_id': 15,
            'end_time': 1698271754,
            'event_data': {
              'alarm': {
                'alarm_description': '0300',
                'alarm_threshold': 0,
                'alarm_type': 23,
                'new_alarm_flag': 1
              },
              'location': {
                'data': {
                  'acc_on': false,
                  'direction': 110,
                  'odometer_reading': 516621,
                  'speed': 0,
                  'speed_cm_sec': 0
                },
                'datetime': '2023-10-25T22:09:14+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 301,
                  'last_accon_time': '2023-10-25T21:19:31Z'
                },
                'latitude': -37.56231,
                'longitude': 143.86505,
                'timestamp': 1698271754
              }
            },
            'event_id': 119814,
            'event_subtype': 0,
            'event_type': 1003,
            'link': '/device/213LUL2020000004?event_id=119814',
            'source': 'system',
            'start_time': 1698271754
          },
          {
            'device_id': 15,
            'end_time': 1698272301,
            'event_data': {
              'alarm': {
                'alarm_description': '0200',
                'alarm_threshold': 0,
                'alarm_type': 22,
                'new_alarm_flag': 1
              },
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 110,
                  'odometer_reading': 516621,
                  'speed': 0,
                  'speed_cm_sec': 0
                },
                'datetime': '2023-10-25T22:18:21+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 0,
                  'last_accon_time': '2023-10-25T22:18:21Z'
                },
                'latitude': -37.56231,
                'longitude': 143.86505,
                'timestamp': 1698272301
              }
            },
            'event_id': 119815,
            'event_subtype': 0,
            'event_type': 1002,
            'link': '/device/213LUL2020000004?event_id=119815',
            'source': 'system',
            'start_time': 1698272301
          },
          {
            'device_id': 15,
            'end_time': 1698272301,
            'event_data': {
              'alarm': {
                'alarm_description': '0200',
                'alarm_threshold': 0,
                'alarm_type': 22,
                'new_alarm_flag': 1
              },
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 110,
                  'odometer_reading': 516652,
                  'speed': 0,
                  'speed_cm_sec': 0
                },
                'datetime': '2023-10-25T22:18:21+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 0,
                  'last_accon_time': '2023-10-25T22:18:21Z'
                },
                'latitude': -37.56231,
                'longitude': 143.86505,
                'timestamp': 1698272301
              }
            },
            'event_id': 119816,
            'event_subtype': 0,
            'event_type': 1002,
            'link': '/device/213LUL2020000004?event_id=119816',
            'source': 'system',
            'start_time': 1698272301
          },
          {
            'device_id': 15,
            'end_time': 1698272301,
            'event_data': {
              'alarm': {
                'alarm_description': '0200',
                'alarm_threshold': 0,
                'alarm_type': 22,
                'new_alarm_flag': 1
              },
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 110,
                  'odometer_reading': 516652,
                  'speed': 0,
                  'speed_cm_sec': 0
                },
                'datetime': '2023-10-25T22:18:21+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 0,
                  'last_accon_time': '2023-10-25T22:18:21Z'
                },
                'latitude': -37.56231,
                'longitude': 143.86505,
                'timestamp': 1698272301
              }
            },
            'event_id': 119817,
            'event_subtype': 0,
            'event_type': 1002,
            'link': '/device/213LUL2020000004?event_id=119817',
            'source': 'system',
            'start_time': 1698272301
          }
        ],
        'fuel_consumed': 308,
        'id': 79198,
        'ignition_on': null,
        'kms_travelled': 32.9936,
        'maximum_speed': 95,
        'metadata': {
          'fuel_history': {
            '2023-10-25T21:17:21Z': 0,
            '2023-10-25T21:19:31Z': 301,
            '2023-10-25T22:18:21Z': 7
          },
          'location_lookup_end': true,
          'location_lookup_start': true,
          'odometer_reading_end': 516652.429,
          'odometer_reading_start': 516616
        },
        'modified': false,
        'n_locations': 717,
        'start_latitude': -37.56863,
        'start_location_address': {
          'created': '2023-09-12T02:59:31',
          'data': {
            'address_text': '217 York St, Ballarat East VIC 3350, Australia',
            'geocode_result': {
              'address_components': [
                {
                  'long_name': '217',
                  'short_name': '217',
                  'types': [
                    'street_number'
                  ]
                },
                {
                  'long_name': 'York Street',
                  'short_name': 'York St',
                  'types': [
                    'route'
                  ]
                },
                {
                  'long_name': 'Ballarat East',
                  'short_name': 'Ballarat East',
                  'types': [
                    'locality',
                    'political'
                  ]
                },
                {
                  'long_name': 'City of Ballarat',
                  'short_name': 'City of Ballarat',
                  'types': [
                    'administrative_area_level_2',
                    'political'
                  ]
                },
                {
                  'long_name': 'Victoria',
                  'short_name': 'VIC',
                  'types': [
                    'administrative_area_level_1',
                    'political'
                  ]
                },
                {
                  'long_name': 'Australia',
                  'short_name': 'AU',
                  'types': [
                    'country',
                    'political'
                  ]
                },
                {
                  'long_name': '3350',
                  'short_name': '3350',
                  'types': [
                    'postal_code'
                  ]
                }
              ],
              'formatted_address': '217 York St, Ballarat East VIC 3350, Australia',
              'geometry': {
                'location': {
                  'lat': -37.5687487,
                  'lng': 143.8737933
                },
                'location_type': 'ROOFTOP',
                'viewport': {
                  'northeast': {
                    'lat': -37.5673997197085,
                    'lng': 143.8751422802915
                  },
                  'southwest': {
                    'lat': -37.5700976802915,
                    'lng': 143.8724443197085
                  }
                }
              },
              'place_id': 'ChIJPwra3gZF0WoR9qWR5WiaO9E',
              'plus_code': {
                'compound_code': 'CVJF+GG Ballarat East VIC, Australia',
                'global_code': '4RJ5CVJF+GG'
              },
              'types': [
                'street_address'
              ]
            }
          },
          'id': 1060,
          'location': {
            'lat': -37.5687487,
            'lng': 143.8737933
          },
          'location_type': 'address'
        },
        'start_longitude': 143.8738,
        'start_time': 1698268641,
        'state': 'MOVING',
        'tags': {},
        'time_taken': 3870,
        'trip_data': [],
        'work_use': true
      },
      testTripBoth: {
        'annotation': 'I went to the bathroom twice on this trip',
        'device_imei': '213LUL2020000004',
        'end_latitude': -37.56215,
        'end_location_address': {
          'created': '2023-08-17T05:02:52',
          'data': {
            'address_text': '53 Victoria St, Bakery Hill VIC 3350, Australia',
            'geocode_result': {
              'address_components': [
                {
                  'long_name': '53',
                  'short_name': '53',
                  'types': [
                    'street_number'
                  ]
                },
                {
                  'long_name': 'Victoria Street',
                  'short_name': 'Victoria St',
                  'types': [
                    'route'
                  ]
                },
                {
                  'long_name': 'Bakery Hill',
                  'short_name': 'Bakery Hill',
                  'types': [
                    'locality',
                    'political'
                  ]
                },
                {
                  'long_name': 'City of Ballarat',
                  'short_name': 'City of Ballarat',
                  'types': [
                    'administrative_area_level_2',
                    'political'
                  ]
                },
                {
                  'long_name': 'Victoria',
                  'short_name': 'VIC',
                  'types': [
                    'administrative_area_level_1',
                    'political'
                  ]
                },
                {
                  'long_name': 'Australia',
                  'short_name': 'AU',
                  'types': [
                    'country',
                    'political'
                  ]
                },
                {
                  'long_name': '3350',
                  'short_name': '3350',
                  'types': [
                    'postal_code'
                  ]
                }
              ],
              'formatted_address': '53 Victoria St, Bakery Hill VIC 3350, Australia',
              'geometry': {
                'bounds': {
                  'northeast': {
                    'lat': -37.5621042,
                    'lng': 143.8670506
                  },
                  'southwest': {
                    'lat': -37.5623086,
                    'lng': 143.8668352
                  }
                },
                'location': {
                  'lat': -37.5622057,
                  'lng': 143.8669533
                },
                'location_type': 'ROOFTOP',
                'viewport': {
                  'northeast': {
                    'lat': -37.5608574197085,
                    'lng': 143.8682918802915
                  },
                  'southwest': {
                    'lat': -37.5635553802915,
                    'lng': 143.8655939197085
                  }
                }
              },
              'place_id': 'ChIJBw1GNFVE0WoRHgfwoZtd3Ms',
              'types': [
                'premise'
              ]
            }
          },
          'id': 11,
          'location': {
            'lat': -37.5622057,
            'lng': 143.8669533
          },
          'location_type': 'address'
        },
        'end_longitude': 143.86688,
        'end_time': 1698272511,
        'events': [
          {
            'device_id': 15,
            'end_time': 1698268641,
            'event_data': {
              'alarm': {
                'alarm_description': '0200',
                'alarm_threshold': 0,
                'alarm_type': 22,
                'new_alarm_flag': 1
              },
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 1080,
                  'odometer_reading': 516616,
                  'speed': 0,
                  'speed_cm_sec': 0
                },
                'datetime': '2023-10-25T21:17:21+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 0,
                  'last_accon_time': '2023-10-25T21:17:21Z'
                },
                'latitude': -37.56863,
                'longitude': 143.8738,
                'timestamp': 1698268641
              }
            },
            'event_id': 119802,
            'event_subtype': 0,
            'event_type': 1002,
            'link': '/device/213LUL2020000004?event_id=119802',
            'source': 'system',
            'start_time': 1698268641
          },
          {
            'device_id': 15,
            'end_time': 1698268683,
            'event_data': {
              'alarm': {
                'alarm_description': '0300',
                'alarm_threshold': 0,
                'alarm_type': 23,
                'new_alarm_flag': 1
              },
              'location': {
                'data': {
                  'acc_on': false,
                  'direction': 3390,
                  'odometer_reading': 516616,
                  'speed': 0,
                  'speed_cm_sec': 0
                },
                'datetime': '2023-10-25T21:18:03+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 0,
                  'last_accon_time': '2023-10-25T21:17:21Z'
                },
                'latitude': -37.56861,
                'longitude': 143.87389,
                'timestamp': 1698268683
              }
            },
            'event_id': 119800,
            'event_subtype': 0,
            'event_type': 1003,
            'link': '/device/213LUL2020000004?event_id=119800',
            'source': 'system',
            'start_time': 1698268683
          },
          {
            'device_id': 15,
            'end_time': 1698268683,
            'event_data': {
              'alarm': {
                'alarm_description': '0300',
                'alarm_threshold': 0,
                'alarm_type': 23,
                'new_alarm_flag': 1
              },
              'location': {
                'data': {
                  'acc_on': false,
                  'direction': 3390,
                  'odometer_reading': 516616,
                  'speed': 0,
                  'speed_cm_sec': 0
                },
                'datetime': '2023-10-25T21:18:03+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 0,
                  'last_accon_time': '2023-10-25T21:17:21Z'
                },
                'latitude': -37.56861,
                'longitude': 143.87389,
                'timestamp': 1698268683
              }
            },
            'event_id': 119801,
            'event_subtype': 0,
            'event_type': 1003,
            'link': '/device/213LUL2020000004?event_id=119801',
            'source': 'system',
            'start_time': 1698268683
          },
          {
            'device_id': 15,
            'end_time': 1698268771,
            'event_data': {
              'alarm': {
                'alarm_description': '0200',
                'alarm_threshold': 0,
                'alarm_type': 22,
                'new_alarm_flag': 1
              },
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 3390,
                  'odometer_reading': 516616,
                  'speed': 0,
                  'speed_cm_sec': 0
                },
                'datetime': '2023-10-25T21:19:31+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 0,
                  'last_accon_time': '2023-10-25T21:19:31Z'
                },
                'latitude': -37.56861,
                'longitude': 143.87389,
                'timestamp': 1698268771
              }
            },
            'event_id': 119803,
            'event_subtype': 0,
            'event_type': 1002,
            'link': '/device/213LUL2020000004?event_id=119803',
            'source': 'system',
            'start_time': 1698268771
          },
          {
            'device_id': 15,
            'end_time': 1698268978,
            'event_data': {
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 1500,
                  'odometer_reading': 516620.598,
                  'overspeed_start': 1698268938,
                  'speed': 45,
                  'speed_cm_sec': 1255
                },
                'datetime': '2023-10-25T21:22:50+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 26,
                  'last_accon_time': '2023-10-25T21:19:31Z'
                },
                'latitude': -37.57821,
                'longitude': 143.87581,
                'timestamp': 1698268978
              },
              'speed': 45
            },
            'event_id': 119804,
            'event_subtype': 0,
            'event_type': 3002,
            'link': '/device/213LUL2020000004?event_id=119804',
            'source': 'system',
            'start_time': 1698268978
          },
          {
            'device_id': 15,
            'end_time': 1698269073,
            'event_data': {
              'alarm': {
                'alarm_description': '0100',
                'alarm_threshold': 1,
                'alarm_type': 6,
                'new_alarm_flag': 1
              },
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 1110,
                  'odometer_reading': 516621,
                  'speed': 0,
                  'speed_cm_sec': 0
                },
                'datetime': '2023-10-25T21:24:33+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 32,
                  'last_accon_time': '2023-10-25T21:19:31Z'
                },
                'latitude': -37.58054,
                'longitude': 143.87634,
                'timestamp': 1698269073
              }
            },
            'event_id': 119805,
            'event_subtype': 0,
            'event_type': 3007,
            'link': '/device/213LUL2020000004?event_id=119805',
            'source': 'system',
            'start_time': 1698269073
          },
          {
            'device_id': 15,
            'end_time': 1698269128,
            'event_data': {
              'alarm': {
                'alarm_description': '0100',
                'alarm_threshold': 1,
                'alarm_type': 6,
                'new_alarm_flag': 0
              },
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 1110,
                  'odometer_reading': 516621,
                  'speed': 0,
                  'speed_cm_sec': 0
                },
                'datetime': '2023-10-25T21:25:28+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 34,
                  'last_accon_time': '2023-10-25T21:19:31Z'
                },
                'latitude': -37.58054,
                'longitude': 143.87634,
                'timestamp': 1698269128
              }
            },
            'event_id': 119806,
            'event_subtype': 0,
            'event_type': 3007,
            'link': '/device/213LUL2020000004?event_id=119806',
            'source': 'system',
            'start_time': 1698269128
          },
          {
            'device_id': 15,
            'end_time': 1698269297,
            'event_data': {
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 1970,
                  'odometer_reading': 516620.598,
                  'overspeed_start': 1698269260,
                  'speed': 46,
                  'speed_cm_sec': 1305
                },
                'datetime': '2023-10-25T21:28:11+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 55,
                  'last_accon_time': '2023-10-25T21:19:31Z'
                },
                'latitude': -37.58648,
                'longitude': 143.86702,
                'timestamp': 1698269297
              },
              'speed': 46
            },
            'event_id': 119807,
            'event_subtype': 0,
            'event_type': 3002,
            'link': '/device/213LUL2020000004?event_id=119807',
            'source': 'system',
            'start_time': 1698269297
          },
          {
            'device_id': 15,
            'end_time': 1698269667,
            'event_data': {
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 1850,
                  'odometer_reading': 516620.598,
                  'overspeed_start': 1698269633,
                  'speed': 45,
                  'speed_cm_sec': 1263
                },
                'datetime': '2023-10-25T21:34:20+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 98,
                  'last_accon_time': '2023-10-25T21:19:31Z'
                },
                'latitude': -37.6051,
                'longitude': 143.84056,
                'timestamp': 1698269667
              },
              'speed': 45
            },
            'event_id': 119808,
            'event_subtype': 0,
            'event_type': 3002,
            'link': '/device/213LUL2020000004?event_id=119808',
            'source': 'system',
            'start_time': 1698269667
          },
          {
            'device_id': 15,
            'end_time': 1698270278,
            'event_data': {
              'alarm': {
                'alarm_description': '0100',
                'alarm_threshold': 1,
                'alarm_type': 6,
                'new_alarm_flag': 1
              },
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 380,
                  'odometer_reading': 516621,
                  'speed': 0,
                  'speed_cm_sec': 0
                },
                'datetime': '2023-10-25T21:44:38+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 170,
                  'last_accon_time': '2023-10-25T21:19:31Z'
                },
                'latitude': -37.6788,
                'longitude': 143.82694,
                'timestamp': 1698270278
              }
            },
            'event_id': 119809,
            'event_subtype': 0,
            'event_type': 3007,
            'link': '/device/213LUL2020000004?event_id=119809',
            'source': 'system',
            'start_time': 1698270278
          },
          {
            'device_id': 15,
            'end_time': 1698270293,
            'event_data': {
              'alarm': {
                'alarm_description': '0100',
                'alarm_threshold': 1,
                'alarm_type': 6,
                'new_alarm_flag': 0
              },
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 380,
                  'odometer_reading': 516621,
                  'speed': 0,
                  'speed_cm_sec': 0
                },
                'datetime': '2023-10-25T21:44:53+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 171,
                  'last_accon_time': '2023-10-25T21:19:31Z'
                },
                'latitude': -37.6788,
                'longitude': 143.82694,
                'timestamp': 1698270293
              }
            },
            'event_id': 119810,
            'event_subtype': 0,
            'event_type': 3007,
            'link': '/device/213LUL2020000004?event_id=119810',
            'source': 'system',
            'start_time': 1698270293
          },
          {
            'device_id': 15,
            'end_time': 1698270388,
            'event_data': {
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 50,
                  'odometer_reading': 516620.598,
                  'overspeed_start': 1698270356,
                  'speed': 55,
                  'speed_cm_sec': 1544
                },
                'datetime': '2023-10-25T21:46:25+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 180,
                  'last_accon_time': '2023-10-25T21:19:31Z'
                },
                'latitude': -37.67095,
                'longitude': 143.83191,
                'timestamp': 1698270388
              },
              'speed': 55
            },
            'event_id': 119811,
            'event_subtype': 0,
            'event_type': 3002,
            'link': '/device/213LUL2020000004?event_id=119811',
            'source': 'system',
            'start_time': 1698270388
          },
          {
            'device_id': 15,
            'end_time': 1698270751,
            'event_data': {
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 130,
                  'odometer_reading': 516620.598,
                  'overspeed_start': 1698270721,
                  'speed': 60,
                  'speed_cm_sec': 1691
                },
                'datetime': '2023-10-25T21:52:27+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 233,
                  'last_accon_time': '2023-10-25T21:19:31Z'
                },
                'latitude': -37.60954,
                'longitude': 143.83951,
                'timestamp': 1698270751
              },
              'speed': 60
            },
            'event_id': 119812,
            'event_subtype': 0,
            'event_type': 3002,
            'link': '/device/213LUL2020000004?event_id=119812',
            'source': 'system',
            'start_time': 1698270751
          },
          {
            'device_id': 15,
            'end_time': 1698271316,
            'event_data': {
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 540,
                  'odometer_reading': 516620.598,
                  'overspeed_start': 1698271286,
                  'speed': 55,
                  'speed_cm_sec': 1550
                },
                'datetime': '2023-10-25T22:01:50+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 275,
                  'last_accon_time': '2023-10-25T21:19:31Z'
                },
                'latitude': -37.56928,
                'longitude': 143.85177,
                'timestamp': 1698271316
              },
              'speed': 55
            },
            'event_id': 119813,
            'event_subtype': 0,
            'event_type': 3002,
            'link': '/device/213LUL2020000004?event_id=119813',
            'source': 'system',
            'start_time': 1698271316
          },
          {
            'device_id': 15,
            'end_time': 1698271754,
            'event_data': {
              'alarm': {
                'alarm_description': '0300',
                'alarm_threshold': 0,
                'alarm_type': 23,
                'new_alarm_flag': 1
              },
              'location': {
                'data': {
                  'acc_on': false,
                  'direction': 110,
                  'odometer_reading': 516621,
                  'speed': 0,
                  'speed_cm_sec': 0
                },
                'datetime': '2023-10-25T22:09:14+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 301,
                  'last_accon_time': '2023-10-25T21:19:31Z'
                },
                'latitude': -37.56231,
                'longitude': 143.86505,
                'timestamp': 1698271754
              }
            },
            'event_id': 119814,
            'event_subtype': 0,
            'event_type': 1003,
            'link': '/device/213LUL2020000004?event_id=119814',
            'source': 'system',
            'start_time': 1698271754
          },
          {
            'device_id': 15,
            'end_time': 1698272301,
            'event_data': {
              'alarm': {
                'alarm_description': '0200',
                'alarm_threshold': 0,
                'alarm_type': 22,
                'new_alarm_flag': 1
              },
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 110,
                  'odometer_reading': 516621,
                  'speed': 0,
                  'speed_cm_sec': 0
                },
                'datetime': '2023-10-25T22:18:21+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 0,
                  'last_accon_time': '2023-10-25T22:18:21Z'
                },
                'latitude': -37.56231,
                'longitude': 143.86505,
                'timestamp': 1698272301
              }
            },
            'event_id': 119815,
            'event_subtype': 0,
            'event_type': 1002,
            'link': '/device/213LUL2020000004?event_id=119815',
            'source': 'system',
            'start_time': 1698272301
          },
          {
            'device_id': 15,
            'end_time': 1698272301,
            'event_data': {
              'alarm': {
                'alarm_description': '0200',
                'alarm_threshold': 0,
                'alarm_type': 22,
                'new_alarm_flag': 1
              },
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 110,
                  'odometer_reading': 516652,
                  'speed': 0,
                  'speed_cm_sec': 0
                },
                'datetime': '2023-10-25T22:18:21+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 0,
                  'last_accon_time': '2023-10-25T22:18:21Z'
                },
                'latitude': -37.56231,
                'longitude': 143.86505,
                'timestamp': 1698272301
              }
            },
            'event_id': 119816,
            'event_subtype': 0,
            'event_type': 1002,
            'link': '/device/213LUL2020000004?event_id=119816',
            'source': 'system',
            'start_time': 1698272301
          },
          {
            'device_id': 15,
            'end_time': 1698272301,
            'event_data': {
              'alarm': {
                'alarm_description': '0200',
                'alarm_threshold': 0,
                'alarm_type': 22,
                'new_alarm_flag': 1
              },
              'location': {
                'data': {
                  'acc_on': true,
                  'direction': 110,
                  'odometer_reading': 516652,
                  'speed': 0,
                  'speed_cm_sec': 0
                },
                'datetime': '2023-10-25T22:18:21+00:00',
                'device_imei': '213LUL2020000004',
                'device_type': '213L',
                'fuel_usage': {
                  'current_fuel': 0,
                  'last_accon_time': '2023-10-25T22:18:21Z'
                },
                'latitude': -37.56231,
                'longitude': 143.86505,
                'timestamp': 1698272301
              }
            },
            'event_id': 119817,
            'event_subtype': 0,
            'event_type': 1002,
            'link': '/device/213LUL2020000004?event_id=119817',
            'source': 'system',
            'start_time': 1698272301
          }
        ],
        'fuel_consumed': 308,
        'id': 79198,
        'ignition_on': null,
        'kms_travelled': 32.9936,
        'maximum_speed': 95,
        'metadata': {
          'fuel_history': {
            '2023-10-25T21:17:21Z': 0,
            '2023-10-25T21:19:31Z': 301,
            '2023-10-25T22:18:21Z': 7
          },
          'location_lookup_end': true,
          'location_lookup_start': true,
          'odometer_reading_end': 516652.429,
          'odometer_reading_start': 516616
        },
        'modified': false,
        'n_locations': 717,
        'start_latitude': -37.56863,
        'start_location_address': {
          'created': '2023-09-12T02:59:31',
          'data': {
            'address_text': '217 York St, Ballarat East VIC 3350, Australia',
            'geocode_result': {
              'address_components': [
                {
                  'long_name': '217',
                  'short_name': '217',
                  'types': [
                    'street_number'
                  ]
                },
                {
                  'long_name': 'York Street',
                  'short_name': 'York St',
                  'types': [
                    'route'
                  ]
                },
                {
                  'long_name': 'Ballarat East',
                  'short_name': 'Ballarat East',
                  'types': [
                    'locality',
                    'political'
                  ]
                },
                {
                  'long_name': 'City of Ballarat',
                  'short_name': 'City of Ballarat',
                  'types': [
                    'administrative_area_level_2',
                    'political'
                  ]
                },
                {
                  'long_name': 'Victoria',
                  'short_name': 'VIC',
                  'types': [
                    'administrative_area_level_1',
                    'political'
                  ]
                },
                {
                  'long_name': 'Australia',
                  'short_name': 'AU',
                  'types': [
                    'country',
                    'political'
                  ]
                },
                {
                  'long_name': '3350',
                  'short_name': '3350',
                  'types': [
                    'postal_code'
                  ]
                }
              ],
              'formatted_address': '217 York St, Ballarat East VIC 3350, Australia',
              'geometry': {
                'location': {
                  'lat': -37.5687487,
                  'lng': 143.8737933
                },
                'location_type': 'ROOFTOP',
                'viewport': {
                  'northeast': {
                    'lat': -37.5673997197085,
                    'lng': 143.8751422802915
                  },
                  'southwest': {
                    'lat': -37.5700976802915,
                    'lng': 143.8724443197085
                  }
                }
              },
              'place_id': 'ChIJPwra3gZF0WoR9qWR5WiaO9E',
              'plus_code': {
                'compound_code': 'CVJF+GG Ballarat East VIC, Australia',
                'global_code': '4RJ5CVJF+GG'
              },
              'types': [
                'street_address'
              ]
            }
          },
          'id': 1060,
          'location': {
            'lat': -37.5687487,
            'lng': 143.8737933
          },
          'location_type': 'address'
        },
        'start_longitude': 143.8738,
        'start_time': 1698268641,
        'state': 'MOVING',
        'tags': {},
        'time_taken': 3870,
        'trip_data': [],
        'work_use': true,
        'forms': [
          {
            'created': '2023-10-03T21:59:53',
            'data': {
              'checkedCoolantLevel': true,
              'checkedLeaks': true,
              'checkedLights': true,
              'checkedOilLevel': true,
              'checkedSeatbelts': true,
              'checkedWiperLevel': true,
              'hasEngineAlarm': false,
              'hasOtherProblem': false,
              'hasPanelDamage': false
            },
            'id': 55,
            'owner': {
              'email': 'robert@pro-tekt.com.au',
              'name': 'Robert Layton1',
              'user_id': 1
            },
            'owner_id': 1,
            'related_id': 15,
            'related_type': 'device',
            'state': 'review_required',
            'template': {
              'color': '#d36327',
              'created': '2023-08-17T00:55:06',
              'description': null,
              'icon': 'fa-car',
              'is_public': true,
              'name': 'Prestart Rules Test',
              'owner_id': null,
              'revision_number': 31,
              'schema': {},
              'template_id': 10,
              'valid_related_types': null
            },
            'updated': '2023-10-03T21:59:53'
          },
        ]
      },
      testTags: {
        'offroad': true,
        'wibble': true,
        'personal': true
      },
      testUserData: {
        id: 1,
        name: 'Chuck Tester',
        email: 'chuck@test.org'
      },
      selectedDate: null,
      selectedIcon: null,
      workflowSteps: {
        new: 'New',
        review_required: 'Review Required',
        complete: 'Complete'
      },
      workflowProgress: 0,
      workflowStepsTwo: {
        new: 'New',
        collect_underpants: 'Collect Underpants',
        review_required: '... Something',
        complete: 'Profit'
      },
      workflowProgressTwo: 0,
      tripFormsOne: [
        {
          'created': '2023-10-03T21:59:53',
          'data': {
            'checkedCoolantLevel': true,
            'checkedLeaks': true,
            'checkedLights': true,
            'checkedOilLevel': true,
            'checkedSeatbelts': true,
            'checkedWiperLevel': true,
            'hasEngineAlarm': false,
            'hasOtherProblem': false,
            'hasPanelDamage': false
          },
          'id': 55,
          'owner': {
            'email': 'robert@pro-tekt.com.au',
            'name': 'Robert Layton1',
            'user_id': 1
          },
          'owner_id': 1,
          'related_id': 15,
          'related_type': 'device',
          'state': 'review_required',
          'template': {
            'color': '#d36327',
            'created': '2023-08-17T00:55:06',
            'description': null,
            'icon': 'fa-car',
            'is_public': true,
            'name': 'Prestart Rules Test',
            'owner_id': null,
            'revision_number': 31,
            'schema': {},
            'template_id': 10,
            'valid_related_types': null
          },
          'updated': '2023-10-03T21:59:53'
        },
        {
          'created': '2023-08-29T01:56:42',
          'data': {
            'checkedOil': true,
            'checkedUnderBody': true,
            'firstName': 'What the ',
            'secondName': 'ddwq'
          },
          'id': 25,
          'owner': {
            'email': 'robert@pro-tekt.com.au',
            'name': 'Robert Layton1',
            'user_id': 1
          },
          'owner_id': 1,
          'related_id': 6,
          'related_type': 'device',
          'state': 'complete',
          'template': {
            'color': '#ffc342',
            'created': '2023-08-10T07:29:49',
            'description': null,
            'icon': 'fa-angellist',
            'is_public': true,
            'name': 'Prestart Thing',
            'owner_id': null,
            'revision_number': 3,
            'schema': {},
            'template_id': 9,
            'valid_related_types': null
          },
          'updated': '2023-08-29T01:56:42'
        }
      ]
    }
  },
  created () {
  }
}
</script>

<style scoped lang="scss">
@import '@/variables';

.test-forms {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  overflow: auto;
}

</style>
